import { ISpeakerStatsSpeaker } from 'features/Rooms/SpeakerStats/children/Data';
import { IResource } from 'kits/apiKit3/legacy';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { aHandRaiseButtonDisabled } from 'shared-state/directory/atoms';
import { useShowAttachmentPanel } from 'shared-state/display-options/hooks';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { aPlayingVideoId, aPlayingVideoOwnerId, aParticipantsList } from 'features/LinkAttachmentModalBox/atom';
import { useYoutubeVideoAttachmentManager } from '../comps/Attachments/YoutubeVideoAttachmentManager';

export interface ISpeakerHandRaiseData {
    [id: string]: number;
}

export const useCallState = (roomName: string | undefined, room: IResource | undefined, jitsiAPI: any, callEventListeners: any): any => {
    const [addOnIsVisible, setAddOnIsVisible] = useState(false);
    const [activeAddOn, setActiveAddOn] = useState<string | undefined>();
    const [statsAreVisible, setStatsAreVisible] = useState(false);

    const [cameraIsMuted, setCameraIsMuted] = useState(true);

    const [micIsMuted, setMicIsMuted] = useState(true);

    const [sharingIsOn, setSharingIsOn] = useState(false);

    const [isConnected, setIsConnected] = useState(false);

    const [tileViewIsEnabled, setTileViewIsEnabled] = useState(false);

    const [encryptionIsEnabled, setEncryptionIsEnabled] = useState(false);

    const [isChatVisible, setIsChatVisible] = useState(false);

    const [stats, setStats] = useState<ISpeakerStatsSpeaker[]>([]);

    const [, setIsAttachmentPanelVisible] = useShowAttachmentPanel();

    const localUser = useLocalProfile();

    const [isLocalUserHandRaised, setIsLocalUserHandRaised] = useState<boolean>(false);

    const isRaiseHandButtonDisabled = useRecoilValue(aHandRaiseButtonDisabled);

    const [playingVideoId, setPlayingVideoId] = useRecoilState(aPlayingVideoId);
    const [, setPlayingVideoOwnerId] = useRecoilState(aPlayingVideoOwnerId);
    const [, setParticipantsList] = useRecoilState(aParticipantsList);

    const { resetVideoState, setVideoState } = useYoutubeVideoAttachmentManager(jitsiAPI);

    const refetchParticipantsList = async () => {
        const participants = await jitsiAPI.getParticipantsInfo();
        setParticipantsList(participants);
    };

    const refetchSharedVideoState = async () => {
        const sharedVideoState = await jitsiAPI.getCurrentSharedVideoState();
        refetchParticipantsList();
        setVideoState(sharedVideoState.currentSharedVideoState);
    };

    useEffect(() => {
        setIsAttachmentPanelVisible(false);
        setActiveAddOn(undefined);
    }, [roomName]);

    const toggleEncryption = (enabled: boolean) => {
        jitsiAPI._transport.sendEvent({
            data: [enabled],
            name: 'toggle-e2ee'
        });
        setEncryptionIsEnabled(enabled);
    };

    const executeCommand = (command: string, data?: any) => {
        if (command == 'toggleE2ee') {
            const nextEncryptionState = !encryptionIsEnabled;
            toggleEncryption(nextEncryptionState);
            return;
        }
        if (command == 'toggleAddOn-tldraw') {
            setAddOnIsVisible((prev) => !prev);
            setActiveAddOn('tldraw');
            return;
        }
        if (command == 'toggleAddOn-etherpad') {
            setAddOnIsVisible((prev) => !prev);
            setActiveAddOn('etherpad');
            return;
        }
        if (command == 'toggleAddOn-collaboard') {
            setAddOnIsVisible((prev) => !prev);
            setActiveAddOn('collaboardlinkmanager');
            return;
        }
        if (command == 'toggleAttachmentPanel') {
            setIsAttachmentPanelVisible((panelWasVisible: any) => !panelWasVisible);
            return;
        }
        if (command == 'toggleStats') {
            jitsiAPI?.startCollectSpeakerStats(1200);

            setStatsAreVisible((prevVal) => !prevVal);
            return;
        }
        if (command == 'toggleRaiseHand') {
            if (isRaiseHandButtonDisabled) return;
            setIsLocalUserHandRaised((prevVal) => !prevVal);
        }

        if (command == 'setRoomBackground') {
            jitsiAPI.setBackgroundImage(data, '');
            return;
        }

        jitsiAPI.executeCommand(command, data);
    };

    useEffect(() => {
        jitsiAPI && room && room.attributes.backgroundImageURL && executeCommand('setRoomBackground', room.attributes.backgroundImageURL);
        jitsiAPI && room && refetchParticipantsList();
    }, [room, jitsiAPI]);

    let timerId;

    const defaultEventListeners = [
        {
            eventName: 'screenSharingStatusChanged',
            handler: (e: any) => {
                setSharingIsOn(e.on);
            }
        },
        {
            eventName: 'audioMuteStatusChanged',
            handler: (e: any) => {
                setMicIsMuted(e.muted);
            }
        },
        {
            eventName: 'tileViewChanged',
            handler: (e: any) => {
                setTileViewIsEnabled(e.enabled);
            }
        },
        {
            eventName: 'videoMuteStatusChanged',
            handler: (e: any) => {
                setCameraIsMuted(e.muted);
            }
        },
        {
            eventName: 'videoConferenceJoined',
            handler: () => {
                executeCommand('toggleTileView');
                room && room.attributes.backgroundImageURL && executeCommand('setRoomBackground', room.attributes.backgroundImageURL);
                setIsConnected(true);
                const numberOfParticipants = jitsiAPI?.getNumberOfParticipants();
                localUser?.profileImage && executeCommand('avatarUrl', localUser.profileImage);
                if (room && !room.id.includes('personal') && !room.id.includes('visitor')) {
                    setIsAttachmentPanelVisible(numberOfParticipants < 2);
                }
                refetchSharedVideoState();
            }
        },
        {
            eventName: 'participantJoined',
            handler: async (e: any) => {
                const isJoinedParticipantVideo = playingVideoId === e.id || (playingVideoId === '' && e.displayName === 'Video');
                if (!isJoinedParticipantVideo) {
                    const sharedVideoState = await jitsiAPI.getCurrentSharedVideoState();
                    const delay = 3000;

                    const res = await jitsiAPI.getLocalParticipantId();
                    const isLocalParticipantVideoOwner = res.localParticipantId === sharedVideoState.currentSharedVideoState.ownerId;

                    if (isLocalParticipantVideoOwner && sharedVideoState.currentSharedVideoState.time > 1.0) {
                        const updatedTime =
                            sharedVideoState.currentSharedVideoState.status === 'pause'
                                ? sharedVideoState.currentSharedVideoState.time - 0.01
                                : sharedVideoState.currentSharedVideoState.time + delay / 1000;
                        const updatedState = { ...sharedVideoState.currentSharedVideoState, time: updatedTime };
                        setTimeout(() => {
                            jitsiAPI.updateSharedVideoState(updatedState);
                        }, delay);
                    } else if (!isLocalParticipantVideoOwner) {
                        const sharedVideoState = await jitsiAPI.getCurrentSharedVideoState();

                        setTimeout(() => {
                            jitsiAPI.pinParticipant(sharedVideoState.currentSharedVideoState.videoUrl);
                        }, delay * 2);
                    }
                }

                refetchParticipantsList();
            }
        },
        {
            eventName: 'participantLeft',
            handler: () => {
                refetchSharedVideoState();
            }
        },
        {
            eventName: 'videoConferenceLeft',
            handler: () => {
                resetVideoState();
                setIsConnected(false);
                timerId && clearTimeout(timerId);
            }
        },
        {
            eventName: 'speakerStatsUpdated',
            handler: (e: any) => onStats(e.speakerData)
        },
        {
            eventName: 'sharedVideoStateUpdated',
            handler: () => {
                // TODO: Owner object is called ownerId, should be updated in jitsi meet
                timerId && clearTimeout(timerId);
                refetchSharedVideoState();
                setIsAttachmentPanelVisible(true);
            }
        },
        {
            eventName: 'sharedVideoStopped',
            handler: () => {
                setPlayingVideoOwnerId('');
                setPlayingVideoId('');
                timerId && clearTimeout(timerId);
            }
        },
        {
            eventName: 'chatUpdated',
            handler: (e: any) => {
                console.log('!!! chatUpdated', e);
                setIsChatVisible(e.isOpen);
            }
        }
    ];

    const onStats = (speakerDataRaw: {
        [userId: string]: {
            _dominantSpeakerStart: number;
            totalDominantSpeakerTime: number;
            _hasLeft: boolean;
            displayName: string;
            _isLocalStats: boolean;
            raisedHandTimestamp: number;
            isSharedVideoOwner: boolean;
        };
    }) => {
        const time = Date.now();
        let localUserName: string;

        const calculateActiveDominantSpeakerSpeakingTime = (userInfo: { _dominantSpeakerStart: number }) => {
            if (userInfo._dominantSpeakerStart > 0 && userInfo._dominantSpeakerStart < time) {
                return time - userInfo._dominantSpeakerStart;
            } else return 0;
        };

        const removeMeExpressionFromName = (userInfo: { displayName: string; _isLocalStats: boolean }) => {
            if (userInfo.displayName.endsWith(' (Me)') && userInfo._isLocalStats) {
                localUserName = userInfo.displayName.substring(0, userInfo.displayName.lastIndexOf(' (Me)'));
                return localUserName;
            } else return userInfo.displayName;
        };

        const newSpeakerData = Object.keys(speakerDataRaw).map((userIdKey) => {
            return {
                userId: userIdKey,
                userName: removeMeExpressionFromName(speakerDataRaw[userIdKey]),
                speakerTime: speakerDataRaw[userIdKey].totalDominantSpeakerTime + calculateActiveDominantSpeakerSpeakingTime(speakerDataRaw[userIdKey]),
                hasLeft: speakerDataRaw[userIdKey]._hasLeft,
                raisedHandAt: speakerDataRaw[userIdKey].raisedHandTimestamp,
                isSharedVideoOwner: speakerDataRaw[userIdKey].isSharedVideoOwner
            };
        });

        const uniqueNames = newSpeakerData.map((speakerData) => speakerData.userName).filter((element, index, array) => array.indexOf(element) === index);

        const uniqueSpeakerStats: ISpeakerStatsSpeaker[] = [];

        uniqueNames.forEach((uniqueUserName) => {
            const speakersWithSameUsernameData = newSpeakerData.filter((speakerData) => speakerData.userName == uniqueUserName);
            const accumulatedSpeakerTime = speakersWithSameUsernameData.reduce((previousValue, currentValue) => previousValue + currentValue.speakerTime, 0);
            const activeSpeaker = speakersWithSameUsernameData.find((speakerWithSameUsernameData) => speakerWithSameUsernameData.hasLeft == false);
            const userIdForStats = activeSpeaker ? activeSpeaker.userId : speakersWithSameUsernameData[0].userId;
            uniqueSpeakerStats.push({
                userId: userIdForStats,
                userName: uniqueUserName,
                speakerTime: accumulatedSpeakerTime,
                raisedHandAt: activeSpeaker && activeSpeaker.raisedHandAt ? activeSpeaker.raisedHandAt : 0,
                hasLeft: activeSpeaker === undefined,
                isSharedVideoOwner: activeSpeaker === undefined ? false : activeSpeaker.isSharedVideoOwner
            });
        });

        const moveLocalUserStatsToFirstPosition = (uniqueSpeakerStats: ISpeakerStatsSpeaker[]) => {
            uniqueSpeakerStats.forEach(function (item, i) {
                if (item.userName == localUser?.displayName || item.userName == localUserName) {
                    uniqueSpeakerStats.splice(i, 1);
                    uniqueSpeakerStats.unshift(item);
                }
            });
        };

        const localUserStats = uniqueSpeakerStats.find((item) => item.userName == localUser?.displayName || item.userName == localUserName);
        localUserStats && localUserStats.raisedHandAt > 0 != isLocalUserHandRaised && setIsLocalUserHandRaised(localUserStats.raisedHandAt > 0);

        const nonHandRaisedUsersStats = uniqueSpeakerStats.filter((userStats) => userStats.raisedHandAt == 0);

        moveLocalUserStatsToFirstPosition(nonHandRaisedUsersStats);

        const handRaisedUsersStats = uniqueSpeakerStats.filter((userStats) => userStats.raisedHandAt > 0).sort((a, b) => a.raisedHandAt - b.raisedHandAt);

        const sortedSpeakerStats = handRaisedUsersStats.concat(nonHandRaisedUsersStats);

        setStats(sortedSpeakerStats);
    };

    const eventListeners = callEventListeners ? [...defaultEventListeners, ...callEventListeners] : defaultEventListeners;

    return {
        executeCommand,
        eventListeners,
        addOnIsVisible,
        activeAddOn,
        cameraIsMuted,
        micIsMuted,
        sharingIsOn,
        isConnected,
        tileViewIsEnabled,
        encryptionIsEnabled,
        stats,
        statsAreVisible,
        isLocalUserHandRaised,
        isChatVisible
    };
};
