import { IDynamicFormElementProps } from 'components/DynamicForm/DynamicForm';
import IvicosStrings from 'kits/language/stringKit';
import { IProfile } from 'shared-state/identity/types';

export interface ICreateInvitationPanelState {
    formFields: IDynamicFormElementProps[];
    onCreateInviteFormChange: (key: string, newValue: any) => void;
    onCreateInvitation: any;
    isDisable: boolean;
    updateIsOpen: boolean;
    onModalDismiss: any;
    invitationLinkWithCampusName: string;
    editablePartOfTheLink: string;
    setUpdateIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onUpdateInvitationCustomUrl: () => void;
    customPathError: string;
    onChangeCustomUrlInputField: (value: string | undefined) => void;
    selectedArea: boolean;
    fetchedRooms: IRoomListsDetails[];
    newCreatedInvitation: IParsedInvitation | undefined;
    setNewCreatedInvitation: React.Dispatch<React.SetStateAction<IParsedInvitation | undefined>>;
    newEditablePartOfTheLink: string;
    setChanges: React.Dispatch<React.SetStateAction<any>>;
    creatorOfTheInvitation: IProfile | undefined;
    emailSubject: string;
    emailBody: string;
    customizedVideoUrlForWaitingRoom: string;
    setCustomizedVideoUrlForWaitingRoom: React.Dispatch<React.SetStateAction<string>>;
    onSavingVideoUrlForWaitingRoom: (defaultVideoIsUsed: boolean, defaultVideoAtVisitorEntrance?: string) => void;
    toggleAlert: () => void;
    displayAlert: boolean;
}

export interface IAreaListsDetails {
    key: string;
    text: string;
}
export interface IRoomListsDetails {
    key: string;
    text: string;
    video: boolean;
}

const times = [
    { key: '00:00', text: '00:00' },
    { key: '00:30', text: '00:30' },
    { key: '01:00', text: '01:00' },
    { key: '01:30', text: '01:30' },
    { key: '02:00', text: '02:00' },
    { key: '02:30', text: '02:30' },
    { key: '03:00', text: '03:00' },
    { key: '03:30', text: '03:30' },
    { key: '04:00', text: '04:00' },
    { key: '04:30', text: '04:30' },
    { key: '05:00', text: '05:00' },
    { key: '05:30', text: '05:30' },
    { key: '06:00', text: '06:00' },
    { key: '06:30', text: '06:30' },
    { key: '07:00', text: '07:00' },
    { key: '07:30', text: '07:30' },
    { key: '08:00', text: '08:00' },
    { key: '08:30', text: '08:30' },
    { key: '09:00', text: '09:00' },
    { key: '09:30', text: '09:30' },
    { key: '10:00', text: '10:00' },
    { key: '10:30', text: '10:30' },
    { key: '11:00', text: '11:00' },
    { key: '11:30', text: '11:30' },
    { key: '12:00', text: '12:00' },
    { key: '12:30', text: '12:30' },
    { key: '13:00', text: '13:00' },
    { key: '13:30', text: '13:30' },
    { key: '14:00', text: '14:00' },
    { key: '14:30', text: '14:30' },
    { key: '15:00', text: '15:00' },
    { key: '15:30', text: '15:30' },
    { key: '16:00', text: '16:00' },
    { key: '16:30', text: '16:30' },
    { key: '17:00', text: '17:00' },
    { key: '17:30', text: '17:30' },
    { key: '18:00', text: '18:00' },
    { key: '18:30', text: '18:30' },
    { key: '19:00', text: '19:00' },
    { key: '19:30', text: '19:30' },
    { key: '20:00', text: '20:00' },
    { key: '20:30', text: '20:30' },
    { key: '21:00', text: '21:00' },
    { key: '21:30', text: '21:30' },
    { key: '22:00', text: '22:00' },
    { key: '22:30', text: '22:30' },
    { key: '23:00', text: '23:00' },
    { key: '23:30', text: '23:30' }
];

const now = new Date();
const currentTime = now.getHours();

const fromTimesSingle = () => {
    const fromTimesAfterNow = times.map((time) => {
        const currentTimeNumber = Number(time.key.slice(0, 2));
        if (currentTimeNumber >= currentTime) {
            return time;
        } else return;
    });

    const filteredFromTimes = fromTimesAfterNow.filter(function (x) {
        return x !== undefined;
    });
    return filteredFromTimes;
};

const toTimesSingle = (validFromTime: any) => {
    const toTimes = times.map((time) => {
        const currentTimeNumber = Number(time.key.slice(0, 2));
        if (validFromTime) {
            const validFromTimeNumber = Number(validFromTime.selectedKey.slice(0, 2));

            if (currentTimeNumber >= validFromTimeNumber) {
                return time;
            } else return;
        } else if (!validFromTime) {
            if (currentTimeNumber >= currentTime) {
                return time;
            } else return;
        }
    });
    const filteredToTimes = toTimes.filter(function (x) {
        return x !== undefined;
    });

    if (validFromTime && validFromTime.selectedKey.slice(3, 4) == '0') {
        filteredToTimes.shift();
    } else {
        filteredToTimes.shift();
        filteredToTimes.shift();
    }
    return filteredToTimes;
};

const fromTimesRecurring = (validToTime: any) => {
    const fromTimesBeforeFrom = times.map((time) => {
        if (validToTime) {
            const currentTimeNumber = Number(time.key.slice(0, 2));
            const validFromTimeNumber = Number(validToTime.selectedKey.slice(0, 2));
            if (currentTimeNumber <= validFromTimeNumber) {
                return time;
            } else return;
        }
    });
    const filteredFromTimesRecurring = fromTimesBeforeFrom.filter(function (x) {
        return x !== undefined;
    });
    const timeToRemove = filteredFromTimesRecurring.pop();
    const filteredFromTimesRecurringClean = filteredFromTimesRecurring.filter((t: any) => t.key !== timeToRemove);
    let fromTimesRecurring: any;
    if (validToTime && validToTime.selectedKey.slice(3, 4) == '0') {
        const anotherTimeToRemove = filteredFromTimesRecurringClean.pop();
        fromTimesRecurring = filteredFromTimesRecurringClean.filter((t: any) => t.key !== anotherTimeToRemove);
    } else {
        fromTimesRecurring = filteredFromTimesRecurringClean;
    }
    return fromTimesRecurring;
};

const toTimesRecurring = (validFromTime: any) => {
    const toTimesAfterFrom = times.map((time) => {
        if (validFromTime) {
            const currentTimeNumber = Number(time.key.slice(0, 2));
            const validFromTimeNumber = Number(validFromTime.selectedKey.slice(0, 2));
            if (currentTimeNumber >= validFromTimeNumber) {
                return time;
            } else return;
        }
    });
    const filteredToTimesRecurring = toTimesAfterFrom.filter(function (x) {
        return x !== undefined;
    });
    if (validFromTime && validFromTime.selectedKey.slice(3, 4) == '3') {
        filteredToTimesRecurring.shift();
    }
    filteredToTimesRecurring.shift();
    return filteredToTimesRecurring;
};

const datesAreOnSameDay = (first: Date | undefined, second: Date | undefined) => {
    if (first && second)
        if (first?.getFullYear() === second?.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate()) {
            return true;
        } else return false;
};

const day = new Date();
const today = day.setHours(0, 0, 0);
const todayDate = new Date(today);

export const formFieldsForInvitationCreation: (
    changes: any,
    selectedFromDate: any,
    selectedToDate: any,
    selectedMembers: any,
    areaNamesList: any,
    fetchedRooms: any,
    members: any
) => IDynamicFormElementProps[] = (changes, selectedFromDate, selectedToDate, selectedMembers, areaNamesList, fetchedRooms, members) => {
    return [
        {
            key: 'name',
            label: IvicosStrings.titleTitle,
            value: changes.name,
            errorMessage: changes.name.length !== 0 && changes.name.length < 2 ? IvicosStrings.minimumCharacterLimitForTitle : '',
            type: 'text-field-with-max-length',
            callout: IvicosStrings.calloutTitle,
            maxLength: 72
        },
        {
            key: 'validity',
            label: IvicosStrings.validityTitle,
            type: 'choicepicker',
            options: [
                { key: 'single', text: IvicosStrings.eventTypeSingle },
                { key: 'recurring', text: IvicosStrings.eventTypeRecurring }
            ],
            callout: IvicosStrings.invitationValidityCallout,
            value: changes['validity'] && changes['validity']
        },

        changes.validity !== 'recurring'
            ? { key: 'validFromDate', label: IvicosStrings.validFromTitle, value: changes['validFromDate'], type: 'datepicker', minDate: day }
            : {
                  key: 'recurringType',
                  label: IvicosStrings.validTitle,
                  value: {
                      selectedKey: changes['recurringType'] && changes['recurringType'].selectedKey,
                      options: [
                          { key: 'everyDay', text: IvicosStrings.RecurringTypeEveryDay },
                          { key: 'everyWeek', text: IvicosStrings.RecurringTypeEveryWeek }
                      ]
                  },
                  type: 'dropdown'
              },
        changes.validity !== 'recurring'
            ? {
                  key: 'validFromTime',
                  label: '',
                  value: {
                      selectedKey: (changes['validFromTime'] && changes['validFromTime'].selectedKey) || '5:00',
                      options: changes['validFromDate'] && datesAreOnSameDay(todayDate, selectedFromDate) ? fromTimesSingle() : times
                  },
                  type: 'dropdown',
                  height: '360px'
              }
            : {
                  key: 'on',
                  label: IvicosStrings.onTitle,
                  value: {
                      selectedKey: changes['on'] && changes['on'].selectedKey,
                      options: [
                          { key: 'monday', text: IvicosStrings.monday },
                          { key: 'tuesday', text: IvicosStrings.tuesday },
                          { key: 'wednesday', text: IvicosStrings.wednesday },
                          { key: 'thursday', text: IvicosStrings.thursday },
                          { key: 'friday', text: IvicosStrings.friday },
                          { key: 'saturday', text: IvicosStrings.saturday },
                          { key: 'sunday', text: IvicosStrings.sunday }
                      ]
                  },
                  type: 'dropdown',
                  disabled: changes['recurringType'] && changes['recurringType'].selectedKey == 'everyWeek' ? false : true
              },
        changes.validity !== 'recurring'
            ? {
                  key: 'validToDate',
                  label: IvicosStrings.to,
                  value: changes['validToDate'],
                  type: 'datepicker',
                  minDate: (changes['validFromDate'] && changes['validFromDate']) || day
              }
            : {
                  key: 'validFromTime',
                  label: IvicosStrings.from,
                  value: {
                      selectedKey: (changes['validFromTime'] && changes['validFromTime'].selectedKey) || '5:00',
                      options: changes.validity == 'recurring' && changes['validToTime'] ? fromTimesRecurring(changes['validToTime']) : times
                  },
                  type: 'dropdown',
                  height: '300px'
              },
        changes.validity !== 'recurring'
            ? {
                  key: 'validToTime',
                  label: '',
                  value: {
                      selectedKey: (changes['validToTime'] && changes['validToTime'].selectedKey) || '5:00',
                      options: changes['validToDate'] && datesAreOnSameDay(selectedFromDate, selectedToDate) ? toTimesSingle(changes['validFromTime']) : times
                  },
                  type: 'dropdown',
                  height: '300px'
              }
            : {
                  key: 'validToTime',
                  label: IvicosStrings.to,
                  value: {
                      selectedKey: (changes['validToTime'] && changes['validToTime'].selectedKey) || '5:00',
                      options: changes.validity == 'recurring' && changes['validFromTime'] ? toTimesRecurring(changes['validFromTime']) : times
                  },
                  type: 'dropdown',
                  height: '300px'
              },
        {
            key: 'hosts',
            label: IvicosStrings.selectedHostTitle,
            description: IvicosStrings.hostSelectorDescription,
            value: {
                selectedItems: selectedMembers,
                membersList: members,
                isVisible: true
            },
            type: 'personaSelector',
            placeholder: IvicosStrings.hostSelectorPlaceholder
        },
        {
            key: 'visitorEntrance',
            label: IvicosStrings.typeTitle,
            type: 'choicepicker',
            value: changes['visitorEntrance'] && changes['visitorEntrance'],
            options: [
                { key: 'direct', text: IvicosStrings.InvitationTypeDirect },
                { key: 'pickup', text: IvicosStrings.InvitationTypePickup }
            ],
            callout: IvicosStrings.invitationTypeCallout
        },
        changes['visitorEntrance'] !== 'pickup'
            ? {
                  key: 'area',
                  label: IvicosStrings.areaTitle,
                  value: {
                      selectedKey: (changes.area && changes.area.selectedKey) || 'area area',
                      options: areaNamesList
                  },
                  type: 'dropdown'
              }
            : {
                  key: 'message',
                  label: IvicosStrings.welcomeMessageTitle,
                  value: changes.message || '',
                  type: 'multiline-text',
                  maxLength: 272
              },
        changes['visitorEntrance'] !== 'pickup'
            ? {
                  key: 'room',
                  label: IvicosStrings.roomTitle,
                  value: {
                      selectedKey: changes.room && changes.room.selectedKey,
                      options: fetchedRooms
                  },
                  type: 'dropdown',
                  disabled: fetchedRooms.length !== 0 && changes.area && changes.area.selectedKey !== undefined ? false : true
              }
            : { key: 'empty', label: '', type: 'section-heading' }
    ];
};
export const months = [
    IvicosStrings.january,
    IvicosStrings.february,
    IvicosStrings.march,
    IvicosStrings.april,
    IvicosStrings.may,
    IvicosStrings.june,
    IvicosStrings.july,
    IvicosStrings.august,
    IvicosStrings.september,
    IvicosStrings.october,
    IvicosStrings.november,
    IvicosStrings.december
];

export const days = [
    IvicosStrings.sunday,
    IvicosStrings.monday,
    IvicosStrings.tuesday,
    IvicosStrings.wednesday,
    IvicosStrings.thursday,
    IvicosStrings.friday,
    IvicosStrings.saturday
];

export interface IParsedInvitation {
    area?: string;
    hosts?: string[];
    name?: string;
    message?: string;
    on?: { selectedKey: string };
    recurringType?: { selectedKey: string };
    room: string;
    options?: string[];
    selectedKey?: string;
    validFromDate?: Date;
    validFromTime?: string;
    validToDate?: Date;
    validToTime?: string;
    validity?: string;
    visitorEntrance?: string;
    invitationId: string;
    type: string;
    repeat: string;
    customUrl: string;
}
