import { useRecoilState } from 'recoil';
import { aIsYoutubeVideoVisible } from 'routes/WaitingArea/atom';
import { idpService } from 'services/api/identity-provider.service';
import { SERVER_EMIT_TOKEN_TO_CONFIRM_VISITOR } from 'services/socket-connection/events';
import { TEventHandler, TVisitorToken } from 'services/socket-connection/types';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { useCurrentRoomId } from 'shared-state/location/hook';

const useVisitorTokenHandler = () => {
    const localProfile = useLocalProfile();
    const [currentRoomId] = useCurrentRoomId();

    const handleVisitorToken = (data: TVisitorToken) => {
        console.log('[RTA] Received a token', data);

        if (data.meta?.forUser !== localProfile?.uid || !currentRoomId?.includes('visitor')) return;

        idpService.useConfirmationToken(data.token);

        if (data.meta?.attachedRoom && data.meta.attachedArea) {
            const { attachedRoom, attachedArea, noVideoRoom } = data.meta;
            noVideoRoom && sessionStorage.setItem(`noVideo-${attachedRoom}`, attachedRoom);

            setTimeout(() => {
                window.location.href = `/areas/${attachedArea}/rooms/${attachedRoom}`;
            }, 500);
        }
    };
    return handleVisitorToken;
};

const useTheVisibilityOfVideoHandler = () => {
    const [, setIsYoutubeVideoVisible] = useRecoilState(aIsYoutubeVideoVisible);
    const handleTheVisibilityOfVideo = (data: any) => {
        setIsYoutubeVideoVisible(data.isYoutubeVideoVisible);
        sessionStorage.setItem('isYoutubeVideoVisible', data.isYoutubeVideoVisible);
    };

    return handleTheVisibilityOfVideo;
};

export const useVisitorHandlers = (): TEventHandler[] => {
    const handleVisitorToken = useVisitorTokenHandler();
    const handleTheVisibilityOfVideo = useTheVisibilityOfVideoHandler();

    return [
        {
            eventName: SERVER_EMIT_TOKEN_TO_CONFIRM_VISITOR,
            callback: handleVisitorToken
        },
        {
            eventName: 'server-emit-stop-showing-video-at-visitor-entrance',
            callback: handleTheVisibilityOfVideo
        }
    ];
};
