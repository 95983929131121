import { useRecoilValue } from 'recoil';
import { useNotificationPosition } from 'shared-state/display-options/hooks';
import { useReceivedMessages } from 'shared-state/notifications/hooks';
import { IReceivedMessage, ISortedGroupedNotificationForGuest, ISortedGroupedNotificationForMemosAndInviteAndKnock } from 'shared-state/notifications/types';
import {
    aCloseNotificationBanner,
    aCloseNotificationBannerIds,
    aControlValue,
    aDismissOverflow,
    aExpandNotification,
    aHideNotification,
    aNotificationSenderId,
    aShowViewAllOfNotificationsOnly
} from './hooks/atoms';

interface INotificationsLayerState {
    displayHint: string;
    receivedMessagesWithoutMemoAndInviteAndKnockAndGuestAndRoundTableUpdate: IReceivedMessage[];
    notificationSenderId: string;
    expandNotification: boolean;
    showViewAllOfNotificationsOnly: boolean;
    controlValue: boolean;
    dismissOverflow: boolean;
    //** MEMO(S) & INVITE(S) & KNOCK(S) */
    sortedUniqueReceivedMemoAndInviteAndKnockVisibleMessagesForSender: IReceivedMessage[];
    sortedMemosAndInvitesAndKnocks: ISortedGroupedNotificationForMemosAndInviteAndKnock[];
    //** GUEST(S) */
    sortedUniqueReceivedGuestVisibleMessagesForSender: any;
    sortedGuests: ISortedGroupedNotificationForGuest[];
}

export const useNotificationsLayerState: () => INotificationsLayerState = () => {
    const [displayHint] = useNotificationPosition();
    const [receivedMessages]: [IReceivedMessage[]] = useReceivedMessages();

    //** MEMO(S), INVITE(S), KNOCK(S), GUEST(S)-- start */
    const hideNotification = useRecoilValue(aHideNotification);
    const notificationSenderId = useRecoilValue(aNotificationSenderId);
    const closeNotificationBanner = useRecoilValue(aCloseNotificationBanner);
    const closeNotificationBannerIds = useRecoilValue(aCloseNotificationBannerIds);

    const expandNotification = useRecoilValue(aExpandNotification);

    const showViewAllOfNotificationsOnly = useRecoilValue(aShowViewAllOfNotificationsOnly);
    const controlValue = useRecoilValue(aControlValue);
    const dismissOverflow = useRecoilValue(aDismissOverflow);

    const removeDuplicateFromReceivedGroupedMessages = (data: any[], key: string) => {
        const check = new Set();
        return data.filter((obj: any) => !check.has(obj[key]) && check.add(obj[key]));
    };

    const receivedMessagesWithoutMemoAndInviteAndKnockAndGuestAndRoundTableUpdate = receivedMessages.filter(
        (m) =>
            m.type !== 'memo' &&
            m.type !== 'cross-area-memo' &&
            m.type !== 'invite' &&
            m.type !== 'cross-area-invite' &&
            m.type !== 'knock' &&
            m.type !== 'guest' &&
            m.type !== 'round-table-update' &&
            m.type != 'room-attachments-updated'
    );

    let senders: string[] = [];
    const sortMessageSenders = (allMessages: IReceivedMessage[]) => {
        const messageSenders: string[] = [];
        allMessages.map((m) => messageSenders.push(m.sender));
        senders = removeDuplicateSender(messageSenders);
    };

    const removeDuplicateSender = (allSenders: string[]) => {
        return [...new Set(allSenders)];
    };

    sortMessageSenders(receivedMessages);

    //** MEMO(S), INVITE(S), KNOCK(S), GUEST(S)-- end */

    //** MEMO(S) & INVITES(S) & KNOCK(S)-- start */

    const receivedMemoAndInviteAndKnockMessages = receivedMessages.filter(
        (m) =>
            (m.type === 'memo' || m.type === 'cross-area-memo' || m.type === 'invite' || m.type === 'cross-area-invite' || m.type === 'knock') && !m.dismissed
    );

    const uniqueReceivedMemoAndInviteAndKnockVisibleMessages = removeDuplicateFromReceivedGroupedMessages(receivedMemoAndInviteAndKnockMessages, 'id');

    const sortedMemosAndInvitesAndKnocksArr: any[] = [];

    const sortedGroupedNotificationsForSenders = (
        groupedNotificationSender: string[],
        groupedNotifications: IReceivedMessage[],
        sortedGroupedNotificationsArr: any[]
    ) => {
        if (groupedNotificationSender && groupedNotifications) {
            groupedNotificationSender.map((s) => {
                const listOfNotifications = groupedNotifications.filter((notification) => notification.sender === s);
                const listOfGroupedNotifications: any[] = [];

                listOfNotifications.forEach((notification) => {
                    const notificationData = {
                        ...notification,
                        dismissed: closeNotificationBanner.includes(notification.id)
                    };
                    listOfGroupedNotifications.push(notificationData);
                });

                const sortedAllOfMemosAndInvitesAndKnocks = listOfGroupedNotifications.filter((m) => !m.dismissed);

                sortedGroupedNotificationsArr.push({
                    groupedNotificationSender: s,
                    numOfGroupedNotification: sortedAllOfMemosAndInvitesAndKnocks.length,
                    sortedAllOfMemosAndInvitesAndKnocks,
                    typeOfAllOfMemosAndInvitesAndKnocks: sortedAllOfMemosAndInvitesAndKnocks.map((m) => m.type),
                    idsOfAllOfMemosAndInvitesAndKnocks: sortedAllOfMemosAndInvitesAndKnocks.map((m) => m.id)
                });
            });
        } else {
            return;
        }
    };

    sortedGroupedNotificationsForSenders(senders, uniqueReceivedMemoAndInviteAndKnockVisibleMessages, sortedMemosAndInvitesAndKnocksArr);

    const sortedMemosAndInvitesAndKnocks =
        sortedMemosAndInvitesAndKnocksArr && sortedMemosAndInvitesAndKnocksArr.filter((m) => m.numOfGroupedNotification !== 0);

    const sortedUniqueReceivedMemoAndInviteAndKnockVisibleMessagesForSender =
        !hideNotification && uniqueReceivedMemoAndInviteAndKnockVisibleMessages.length > 1
            ? uniqueReceivedMemoAndInviteAndKnockVisibleMessages
                  .filter((m) => !closeNotificationBannerIds.includes(m.id))
                  .filter((m) => m.sender === notificationSenderId)
            : [];

    //** MEMO(S) & INVITES(S) & KNOCK(S)-- end */

    //** GUEST(S)-- start */
    const receivedGuestMessages = receivedMessages.filter((m) => m.type === 'guest' && !m.dismissed && m.identityValue);

    const sortedGuestsArr: ISortedGroupedNotificationForGuest[] = [];

    const sortedGroupedNotificationsForGuestForSenders = (
        groupedNotificationSender: string[],
        groupedNotifications: IReceivedMessage[],
        sortedGroupedNotificationsArr: any[]
    ) => {
        if (groupedNotificationSender && groupedNotifications) {
            groupedNotificationSender.map((s) => {
                const listOfNotifications = groupedNotifications
                    .filter((notification) => notification.sender === s)
                    .filter((notification) => !notification.dismissed);

                const listOfGroupedNotifications: any[] = [];

                listOfNotifications.forEach((notification) => {
                    const notificationData = {
                        ...notification,
                        dismissed: closeNotificationBanner.includes(notification.id)
                    };
                    listOfGroupedNotifications.push(notificationData);
                });

                const filteredListOfGroupedNotifications = listOfGroupedNotifications.filter((n) => !n.dismissed);

                sortedGroupedNotificationsArr.push({
                    groupedNotificationSender: s,
                    numOfGroupedNotification: filteredListOfGroupedNotifications.filter((n) => !n.dismissed).length,
                    idOfGroupedNotification: filteredListOfGroupedNotifications.map((n) => n.id),

                    typeOfGroupedNotification: filteredListOfGroupedNotifications.map((n) => n.type),
                    filteredListOfGroupedNotification: filteredListOfGroupedNotifications
                });
            });
        } else {
            return;
        }
    };

    sortedGroupedNotificationsForGuestForSenders(senders, receivedGuestMessages, sortedGuestsArr);
    const sortedGuests = sortedGuestsArr && sortedGuestsArr.filter((g) => g.numOfGroupedNotification !== 0);

    const sortedUniqueReceivedGuestVisibleMessagesForSender =
        !hideNotification && receivedGuestMessages.length > 1
            ? receivedGuestMessages.filter((m) => !closeNotificationBannerIds.includes(m.id)).filter((m) => m.sender === notificationSenderId)
            : [];
    //** GUEST(S)-- end */

    return {
        displayHint,
        receivedMessagesWithoutMemoAndInviteAndKnockAndGuestAndRoundTableUpdate,
        notificationSenderId,
        expandNotification,
        showViewAllOfNotificationsOnly,
        controlValue,
        dismissOverflow,
        //** MEMO(S) & INVITE(S) & KNOCK(S) */
        sortedUniqueReceivedMemoAndInviteAndKnockVisibleMessagesForSender,
        sortedMemosAndInvitesAndKnocks,
        //** GUEST(S) */
        sortedUniqueReceivedGuestVisibleMessagesForSender,
        sortedGuests
    };
};
