import React from 'react';

import { getTheme, Stack } from '@fluentui/react';
import { IResource } from 'kits/apiKit3/legacy';

import { useWindowWidth } from 'shared-state/display-options/hooks';
import defaultVisitorEntranceBG from '../../../../media/img/default-bgve.jpg';

export interface IOutsideWrapperProps {
    headerActions?: Array<any>;
    campus?: IResource;
    children?: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const theme = getTheme();

const OutsideWrapper: React.FC<IOutsideWrapperProps> = ({ children }) => {
    const rootStyle: React.CSSProperties = {
        backgroundImage: `url(${defaultVisitorEntranceBG})`,
        // backgroundColor: theme.palette.neutralLight,
        height: '100vh',
        width: '100vw',
        alignItems: 'center',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    };
    const { smallDevice, mediumDevice } = useWindowWidth();
    return (
        <Stack style={rootStyle}>
            <Stack
                key="content"
                className="js-o-w"
                grow
                horizontal={!smallDevice && !mediumDevice}
                horizontalAlign="center"
                verticalAlign="stretch"
                style={{ height: 'calc( 100vh - 64px )', minHeight: 'calc( 100vh - 64px )' }}
            >
                {children}
            </Stack>
        </Stack>
    );
};

export default OutsideWrapper;
