/* eslint-disable react/react-in-jsx-scope */
import { INotificationBannerAction } from 'features/NotificationsLayer/NotificationsBanner/NotificationsBanner.view';
import { aSetTopic } from 'features/SetTopicDialog/atom';
import { Check, InfoBubble } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import { ReactElement, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { DefaultValue, useRecoilValue, useSetRecoilState } from 'recoil';
import { useRoom } from 'shared-state/directory/hooks';
import { useControlNameWidthInMemo, useCrossAreaMessageType, useDirectControlNameWidthInMemo } from 'shared-state/display-options/hooks';
import { useCurrentAreaId, useCurrentRoomId } from 'shared-state/location/hook';
import { useDirectMessageComposerUserIds, useMessageComposerUserIds } from 'shared-state/messaging/hooks';
import { IReceivedMessage } from 'shared-state/notifications/types';
import { useCrossAreaUser, useUser } from 'shared-state/presence/hooks';
import { useTabOption } from 'shared-state/tabs/hooks';
import { useEmittingEvents } from 'services/socket-connection/socket-adapters/event-actions/emittingEvents';
import { aIsUserOutOfCampus, aIsUserSwitchedArea } from './atoms';
import { Persona, PersonaSize } from '@fluentui/react';
import Badge from 'components/Badge';

export const useNotificationAssets = (message: IReceivedMessage, onDismiss: Function): any => {
    const _sender = useUser(message.sender);
    const crossAreaSender = useCrossAreaUser(message.sender);
    const sender = useMemo(() => _sender, [message]);

    const [room] = useRoom(message.meta.attachedRoom || '');

    const routeHistory = useNavigate();
    const { inviteUserToRoom, retrieveRoomSessionId } = useEmittingEvents();
    const [currentRoomId] = useCurrentRoomId();
    const [currentAreaId] = useCurrentAreaId();

    const [, setMessageComposerUserIds] = useMessageComposerUserIds();
    const [, setDirectMessageComposerUserIds] = useDirectMessageComposerUserIds();

    const timestamp = message.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    const [, setTabOption] = useTabOption('sidebar', 'visitors');
    const setTopic = useSetRecoilState<any>(aSetTopic);

    const [roomId] = useCurrentRoomId();
    const roundTableTypes = ['round-table-update'];

    const [, setControlNameWidthInMemo] = useControlNameWidthInMemo();
    const [, setDirectControlNameWidthInMemo] = useDirectControlNameWidthInMemo();
    const [, setCrossAreaMessageType] = useCrossAreaMessageType();
    const isUserSwitchedArea = useRecoilValue(aIsUserSwitchedArea);
    const isUserOutOfCampus = useRecoilValue(aIsUserOutOfCampus);

    const getInitialsFromDisplayName = (displayName: string): string => {
        const splitName = displayName?.split(' ');
        return splitName.length > 1 ? `${splitName[0][0].toUpperCase()}${splitName[1][0].toUpperCase()}` : splitName[0][0].toUpperCase();
    };

    const getNotificationIcon = (): ReactElement => {
        const isCrossAreaType = message.type.startsWith('cross-area');
        const senderType = isCrossAreaType ? crossAreaSender : sender;

        // Check for message types that use the Persona component with similar logic
        if (['knock', 'invite', 'memo'].includes(message.type)) {
            return (
                <Persona
                    imageUrl={isUserSwitchedArea[message.sender] ? '' : senderType?.profileImage}
                    imageInitials={getInitialsFromDisplayName(
                        isUserSwitchedArea[message.sender] ? IvicosStrings.someoneMessage : senderType?.displayName || IvicosStrings.someoneMessage
                    )}
                    size={PersonaSize.size40}
                    imageAlt={isUserSwitchedArea[message.sender] ? IvicosStrings.someoneMessage : senderType?.displayName}
                />
            );
        }
        if (['cross-area-invite', 'cross-area-memo'].includes(message.type)) {
            return (
                <Persona
                    imageUrl={isUserOutOfCampus[message.sender] ? '' : senderType?.profileImage || ''}
                    imageInitials={getInitialsFromDisplayName(
                        isUserOutOfCampus[message.sender] ? IvicosStrings.someoneMessage : senderType?.displayName || IvicosStrings.someoneMessage
                    )}
                    size={PersonaSize.size40}
                    imageAlt={isUserOutOfCampus[message.sender] ? IvicosStrings.someoneMessage : senderType?.displayName || IvicosStrings.someoneMessage}
                />
            );
        }

        switch (message.type) {
            case 'confirmation':
                return <Check />;
            case 'room-topic':
                if (message.type === 'room-topic') onDismiss();
                return <Check />;
            case 'guest':
                return <Badge text={'visitor'} size="41" fontSize={'medium'} textColor="#000" />;
            default:
                return <InfoBubble />;
        }
    };

    const string = room?.attributes.displayName || 'hello';
    const crossAreaInviteRoomId = message?.room?.id || 'hello';
    const crossAreaInviteRoom = message?.room?.attributes.displayName || 'hello';
    const crossAreaInviteAreaId = message?.room?.relationships.area || currentAreaId;
    const substring1 = 'persönliches Büro';
    const substring2 = 'personal room';

    const getSenderDisplayName = () =>
        (['knock', 'memo', 'invite'].includes(message.type) && isUserSwitchedArea[message.sender]) ||
        (['cross-area-memo', 'cross-area-invite'].includes(message.type) && isUserOutOfCampus[message.sender])
            ? IvicosStrings.someoneMessage
            : sender?.displayName || crossAreaSender?.displayName || IvicosStrings.someoneMessage;

    const getInvitationMessage = (displayName: string, roomName = '') => {
        const isAreaConditionMet =
            (message.type === 'invite' && isUserSwitchedArea[message.sender]) || (message.type === 'cross-area-invite' && isUserOutOfCampus[message.sender]);

        const baseMessage = isAreaConditionMet ? IvicosStrings.invitedYouToMessage1 : IvicosStrings.inviteYouToMessage1;

        if (roomName) {
            const roomMessage = isAreaConditionMet ? IvicosStrings.invitedYouToMessage : IvicosStrings.inviteYouToMessage;
            return `${displayName} ${roomMessage} ${roomName} ${IvicosStrings.inviteYouToMessage2}`;
        }

        return `${displayName} ${baseMessage}`;
    };

    const getNotificationTitle = () => {
        switch (message.type) {
            case 'knock':
                return `${getSenderDisplayName()} ${
                    isUserSwitchedArea[message.sender] ? IvicosStrings.wasAtYourDoorMessage : IvicosStrings.isAtYourDoorMessage
                }`;

            case 'memo':
            case 'cross-area-memo':
                return `${getSenderDisplayName()} ${
                    isUserSwitchedArea[message.sender] || isUserOutOfCampus[message.sender] ? IvicosStrings.saidMessage : IvicosStrings.saysMessage
                }`;

            case 'invite':
                if (string.includes(substring1) || string.includes(substring2)) {
                    return getInvitationMessage(getSenderDisplayName());
                }
                return getInvitationMessage(getSenderDisplayName(), room?.attributes.displayName);

            case 'cross-area-invite':
                if (crossAreaInviteRoomId.includes('personal')) {
                    return getInvitationMessage(getSenderDisplayName());
                }
                return getInvitationMessage(getSenderDisplayName(), crossAreaInviteRoom);

            case 'info':
                return message.body || '';

            case 'room-topic':
                onDismiss();
                return 'room-topic';

            case 'confirmation':
                return message.body || IvicosStrings.successMessage;

            case 'guest':
                return message.body || IvicosStrings.youHaveTheGuestMessage;

            default:
                if (roundTableTypes.includes(message.type)) {
                    onDismiss();
                    return 'round-table';
                }
                return 'New notification';
        }
    };

    const getNotificationDescription = () => {
        switch (message.type) {
            case 'knock':
                return IvicosStrings.doorKnockNotificationBody;

            case 'memo':
            case 'cross-area-memo':
                return message.body;

            case 'invite':
            case 'cross-area-invite':
                return IvicosStrings.inviteVisitorNotificationBody;

            case 'guest':
                return IvicosStrings.visitorIsWaitingNotificationBody;

            case 'room-topic':
                if (message.body) {
                    setTopic(message.body);
                    localStorage.setItem('topicTitle', message.body);
                    localStorage.setItem('roomIdWithTopic', roomId);
                    onDismiss();
                }
                return message.body || '';

            default:
                return '';
        }
    };

    const getNotificationActions = (): INotificationBannerAction[] => {
        if (message.type == 'memo')
            return [
                {
                    key: 'reply',
                    title: IvicosStrings.replyActionTitle,
                    action: () => {
                        setMessageComposerUserIds([message.sender]);
                        onDismiss();
                        setControlNameWidthInMemo(true);
                    }
                }
            ];

        if (message.type == 'cross-area-memo')
            return [
                {
                    key: 'reply',
                    title: IvicosStrings.replyActionTitle,
                    action: () => {
                        setDirectMessageComposerUserIds([message.sender]);
                        onDismiss();
                        setDirectControlNameWidthInMemo(true);
                        setCrossAreaMessageType(true);
                    }
                }
            ];
        if (message.type == 'knock')
            return [
                {
                    key: 'memo',
                    title: IvicosStrings.sendUserMemoActionTitle,
                    action: () => {
                        setMessageComposerUserIds([message.sender]);
                        onDismiss();
                    }
                },
                {
                    key: 'invite',
                    title: IvicosStrings.inviteThemInActionTitle,
                    action: () => {
                        inviteUserToRoom(message.sender, currentRoomId || '');
                        onDismiss();
                    }
                }
            ];
        if (message.type == 'invite')
            return [
                {
                    key: 'memo',
                    title: IvicosStrings.sendUserMemoActionTitle,
                    action: () => {
                        setMessageComposerUserIds([message.sender]);
                        onDismiss();
                        setControlNameWidthInMemo(true);
                    }
                },
                {
                    key: 'Accept',
                    title: IvicosStrings.acceptInvitationActionTitle,
                    action: () => {
                        const { attachedRoom } = message.meta;
                        const roomPath = `/areas/${currentAreaId}/rooms/${attachedRoom}`;

                        if (attachedRoom?.includes('personal')) {
                            retrieveRoomSessionId(currentAreaId, attachedRoom);
                        } else {
                            routeHistory(roomPath);
                        }

                        localStorage.setItem('jitsiSessionId', message.body || '');
                        onDismiss();
                    }
                }
            ];

        if (message.type == 'cross-area-invite')
            return [
                {
                    key: 'memo',
                    title: IvicosStrings.sendUserMemoActionTitle,
                    action: () => {
                        setDirectMessageComposerUserIds([message.sender]);
                        onDismiss();
                        setDirectControlNameWidthInMemo(true);
                        setCrossAreaMessageType(true);
                    }
                },
                {
                    key: 'Accept',
                    title: IvicosStrings.acceptInvitationActionTitle,
                    action: () => {
                        const { attachedRoom } = message.meta;
                        const roomPath = `/areas/${crossAreaInviteAreaId}/rooms/${attachedRoom}`;

                        if (attachedRoom?.includes('personal')) {
                            retrieveRoomSessionId(crossAreaInviteAreaId, attachedRoom);
                        } else {
                            routeHistory(roomPath);
                        }

                        localStorage.setItem('jitsiSessionId', message.body || '');
                        onDismiss();
                    }
                }
            ];
        if (message.type == 'guest')
            return [
                {
                    key: 'meet',
                    title: IvicosStrings.meetThemActionTitle,
                    action: () => {
                        setTabOption((prevOption: any) => {
                            if (!prevOption) return;
                            if (prevOption instanceof DefaultValue) return prevOption;

                            return { ...prevOption, ...{ isSelected: true } };
                        });
                        onDismiss();

                        console.log('look for the message', message);

                        routeHistory('/meet/' + message.meta.attachedRoom);
                    }
                }
            ];

        return [];
    };

    const title = getNotificationTitle();
    const description = getNotificationDescription();
    const actions = getNotificationActions();
    const messageType = message.type;
    const guestId = message?.meta?.attachedRoom?.split('visitor-')[1] || '';
    const hostsOfGuest = message?.hostsOfGuest || [];
    const messageSenderId = message.sender;

    return { getNotificationIcon, timestamp, title, description, actions, messageType, guestId, hostsOfGuest, messageSenderId };
};
