import React from 'react';
import { Stack, TooltipHost, Text } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { Antenna, EncryptedRoom, EuropeSecure } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import { useWindowWidth } from 'shared-state/display-options/hooks';

export interface IStatusIconBarProps {
    connected?: boolean;
    encryptionEnabled?: boolean;
    cameraMuted?: boolean;
    micMuted?: boolean;
}
const StatusIconBar: React.FC<IStatusIconBarProps> = ({ connected, encryptionEnabled }) => {
    const { smallDevice } = useWindowWidth();
    return (
        <Stack
            style={{ position: 'absolute', zIndex: 99, alignSelf: 'end', bottom: 0, marginRight: 16, marginBottom: 16 }}
            horizontal
            tokens={{ childrenGap: 8 }}
        >
            {connected && (
                <TooltipHost
                    content={
                        smallDevice ? (
                            ''
                        ) : (
                            <Stack className="reveal--on-hover" verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                <Text variant="small">{IvicosStrings.connectedToLabel1}</Text>
                                <Stack horizontal tokens={{ childrenGap: 2 }} verticalAlign="start">
                                    <IC variant="dark" size={16}>
                                        <Antenna />
                                    </IC>
                                    <Text variant="small">
                                        <b>
                                            {IvicosStrings.connectedToLabel2} ({localStorage.getItem('jitsiDomain')?.split('.')[0].toUpperCase()})
                                        </b>
                                        <br />
                                        <i>{IvicosStrings.connectedToLabel3}</i>
                                    </Text>
                                </Stack>
                            </Stack>
                        )
                    }
                >
                    <IC size={32} variant="primary" style={{ opacity: 0.7 }}>
                        <EuropeSecure />
                    </IC>
                </TooltipHost>
            )}
            {encryptionEnabled && (
                <TooltipHost content={smallDevice ? '' : <b>{IvicosStrings.encryptionIsEnabledLabel}</b>}>
                    <IC variant="primary" size={32}>
                        <EncryptedRoom />
                    </IC>
                </TooltipHost>
            )}
        </Stack>
    );
};

export default StatusIconBar;
