import { DefaultButton, DefaultEffects, getTheme, Icon, IconButton, Persona, Stack, Text } from '@fluentui/react';
import Jitsi from '@ivicos/react-jitsi';
import { ConfigOptions, InterfaceConfigOptions, JitsiMeetAPI } from '@ivicos/react-jitsi/dist/types';
import { IC } from 'components/SVGIcon';
import StatusIconBar from 'features/Rooms/RoomView/comps/Icon/StatusIconBar';
import CallActionBar from 'features/Rooms/RoomView/comps/RoomCall/CallActionBar/CallActionBar';
import { ISpeakerStatsSpeaker } from 'features/Rooms/SpeakerStats/children/Data';
import SpeakerStats from 'features/Rooms/SpeakerStats/SpeakerStats';
import { IResource } from 'kits/apiKit3/legacy';
import { IvicosColors } from 'kits/colorKit';
import { ErrorSymbol } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React, { CSSProperties, useState } from 'react';
import { isSafari } from 'react-device-detect';
import { SetterOrUpdater } from 'recoil';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { ICallAction } from '../CallActions';
import AddOn from '../comps/AddOnView/AddOn';
import AttachmentsPanel from '../comps/Attachments/AttachmentPanel/AttachmentsPanel';
import CallLoadingScreen from '../comps/RoomCall/CallLoadingScreen/CallLoadingScreen';
import CallSurface from '../comps/RoomCall/CallSurface/CallSurface';
import FirefoxAlert from '../FirefoxAlert/FirefoxAlert';
import { DeviceSettingsAtVisitorEntrance } from '../DeviceSettingsAtVisitorEntrance/DeviceSettingsAtVisitorEntrance';
import { useIsVisitorAtLandingPage } from 'shared-state/location/hook';

export interface IRoomCallControllerViewProps {
    addOnIsVisible: boolean;
    translateToHideOrShow: () => {
        x: number;
        y: number;
    };
    jitsiShowHideInnerContainerOpacity: number;
    isDragging: boolean;
    setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
    dragStartPosition: {
        x: number;
        y: number;
    };
    setDragStartPosition: React.Dispatch<
        React.SetStateAction<{
            x: number;
            y: number;
        }>
    >;
    movementDirection: {
        x: number;
        y: number;
    };
    setMovementDirection: React.Dispatch<
        React.SetStateAction<{
            x: number;
            y: number;
        }>
    >;
    isHidden?: boolean;
    encryptionIsEnabled: boolean;
    jitsiAPI: any;
    showDeviceSettings: boolean;
    executeCommand: (command: string, data?: any) => void;
    actionBarActions: {
        key: string;
        actions: ICallAction[];
    }[];
    isConnected: boolean;
    micIsMuted: boolean;
    cameraIsMuted: boolean;
    activeAddOnData:
        | {
              id: string;
              type: string;
              attributes: {
                  urlTemplate: string;
                  iconUrl: string;
                  displayName: string;
                  description: string;
              };
              relationships: {};
          }
        | undefined;
    selectedAttachment:
        | {
              addOnId: string;
              ref: string;
              description: string;
          }
        | undefined;
    setSelectedAttachment: SetterOrUpdater<
        | {
              addOnId: string;
              ref: string;
              description: string;
          }
        | undefined
    >;
    jitsiConfig: ConfigOptions;
    interfaceConfig: InterfaceConfigOptions;
    handleAPILoad: (api: any) => void;
    isVideoHidden: boolean;
    setIsVideoHidden: React.Dispatch<React.SetStateAction<boolean>>;
    statsAreVisible: boolean;
    stats: ISpeakerStatsSpeaker[];
    room?: IResource;
    displayName: string;
    jwt: string;
    roomName: string;
    domain: string;
    extractPositionFromEvent: (event: React.MouseEvent) => {
        x: number;
        y: number;
    };
    isLocalUserHandRaised: boolean;
    userIsAloneInOwnPersonalRoom?: boolean;
    profileImageURL: string | undefined;
    sharingIsOn: boolean;
}

const RoomCallControllerView: React.FC<IRoomCallControllerViewProps> = (props) => {
    const {
        addOnIsVisible,
        translateToHideOrShow,
        jitsiShowHideInnerContainerOpacity,
        isDragging,
        setIsDragging,
        dragStartPosition,
        setDragStartPosition,
        movementDirection,
        setMovementDirection,
        isHidden,
        encryptionIsEnabled,
        jitsiAPI,
        executeCommand,
        actionBarActions,
        isConnected,
        micIsMuted,
        cameraIsMuted,
        activeAddOnData,
        selectedAttachment,
        setSelectedAttachment,
        jitsiConfig,
        interfaceConfig,
        handleAPILoad,
        isVideoHidden,
        setIsVideoHidden,
        statsAreVisible,
        stats,
        room,
        displayName,
        jwt,
        roomName,
        domain,
        extractPositionFromEvent,
        isLocalUserHandRaised,
        userIsAloneInOwnPersonalRoom,
        profileImageURL,
        sharingIsOn
    } = props;

    const onRenderLoadingComponent = () => <CallLoadingScreen />;

    const jitsiContainerStyle: CSSProperties = addOnIsVisible
        ? { width: 360, height: 240, position: 'absolute', zIndex: 3000, float: 'left', border: '1px solid #2a88d8' }
        : {
              width: '100%',
              height: '100%',
              minHeight: '100%'
          };

    const startingPosition = { bottom: 48, left: 48 };

    const jitsiShowHideContainerStyle: CSSProperties = addOnIsVisible
        ? {
              width: 380,
              height: 240,
              position: 'absolute',
              zIndex: 3000,
              ...startingPosition,
              verticalAlign: 'top',
              transition: 'all 0.5s ease-in-out',
              transform: `translate(${translateToHideOrShow().x}px, ${translateToHideOrShow().y}px)`,
              display: 'flex',
              justifyContent: 'space-between'
          }
        : { width: '100%', height: '100%', minHeight: '100%' };

    const jitsiShowHideInnerContainerStyle: CSSProperties = addOnIsVisible
        ? {
              width: 380,
              height: 240,
              position: 'absolute',
              zIndex: 3000,
              bottom: 0,
              left: 0,
              verticalAlign: 'top',
              display: 'flex',
              justifyContent: 'space-between'
          }
        : { width: '100%', height: '100%', minHeight: '100%' };

    const theme = getTheme();
    const { smallDevice, mediumDevice } = useWindowWidth();

    const hideButtonContainerStyle: CSSProperties = {
        backgroundColor: '#2a88d8',
        border: '4px grow #2a88d8',
        borderRadius: '0px 4px 4px 0px',
        width: 19,
        height: 80,
        position: 'absolute',
        zIndex: 3000,
        top: 0,
        right: 0,
        verticalAlign: 'middle',
        cursor: 'pointer',
        opacity: jitsiShowHideInnerContainerOpacity
    };

    const onDragStartHandler = (event: React.DragEvent<HTMLElement>) => {
        event.stopPropagation();
        const startPosition = extractPositionFromEvent(event);
        setDragStartPosition(startPosition);
        if (!isDragging) {
            setIsDragging(true);
        }
    };

    const onDragEndHandler = (event: React.DragEvent<HTMLElement>) => {
        event.stopPropagation();
        setIsDragging(false);
        const element = event.currentTarget;
        const parentElement = event.currentTarget.parentElement;

        if (element && parentElement) {
            const parentContainerWidth = parentElement.clientWidth;
            const parentContainerHeight = parentElement.clientHeight;

            const position = extractPositionFromEvent(event);
            const dropMovement = {
                x: position.x - dragStartPosition.x,
                y: position.y - dragStartPosition.y
            };
            const borderThickness = 3;

            const movementLimits = {
                left: -startingPosition.left + borderThickness,
                bottom: startingPosition.bottom - borderThickness,
                right: parentContainerWidth - startingPosition.left - element.getBoundingClientRect().width - borderThickness,
                top: -parentContainerHeight + startingPosition.bottom + element.getBoundingClientRect().height + borderThickness
            };

            if (movementDirection.x + dropMovement.x < movementLimits.left) dropMovement.x = movementLimits.left - movementDirection.x;
            else if (movementDirection.x + dropMovement.x > movementLimits.right) dropMovement.x = movementLimits.right - movementDirection.x;

            if (movementDirection.y + dropMovement.y < movementLimits.top) dropMovement.y = movementLimits.top - movementDirection.y;
            else if (movementDirection.y + dropMovement.y > movementLimits.bottom) dropMovement.y = movementLimits.bottom - movementDirection.y;

            element.style.transform = `translate(${movementDirection.x + dropMovement.x}px, ${movementDirection.y + dropMovement.y}px`;

            setMovementDirection((prevState: any) => {
                return { x: prevState.x + dropMovement.x, y: prevState.y + dropMovement.y };
            });
        }
    };

    const onHandRaiseDismiss = () => {
        executeCommand('toggleRaiseHand');
    };

    const [etherpadAlert, setEtherpadAlert] = useState<boolean>(true);
    const shouldRenderJitsi = !(userIsAloneInOwnPersonalRoom && cameraIsMuted && micIsMuted && !sharingIsOn);
    const [triggerJitsiRender, setTriggerJitsiRender] = useState<boolean>(shouldRenderJitsi);
    const [newJitsiConfig, setNewJitsiConfig] = useState<ConfigOptions>({ ...jitsiConfig, disableDeepLinking: true });

    const [roundTableBorder, setRoundTableBorder] = useState<string>('');
    const [isVisitorAtLandingPage] = useIsVisitorAtLandingPage();

    const shouldShowFireFoxAlert =
        !(window.location.pathname.includes('/w/') || window.location.pathname.includes('/visitorLandingPage/')) && navigator.userAgent.search('Firefox') > -1;

    return (
        <CallSurface isHidden={isHidden} borderColor={encryptionIsEnabled ? IvicosColors.Blau : undefined}>
            {isVisitorAtLandingPage && <DeviceSettingsAtVisitorEntrance jitsiAPI={jitsiAPI}></DeviceSettingsAtVisitorEntrance>}

            <CallActionBar
                visible={true}
                actions={actionBarActions}
                onActionPressed={(a: ICallAction) => {
                    if (!shouldRenderJitsi && !triggerJitsiRender && a.command && ['toggleVideo', 'toggleAudio'].includes(a.command)) {
                        setTriggerJitsiRender(true);
                        a.command == 'toggleVideo'
                            ? setNewJitsiConfig({ ...jitsiConfig, startWithVideoMuted: false })
                            : setNewJitsiConfig({ ...jitsiConfig, startWithAudioMuted: false });
                        return;
                    } else if (shouldRenderJitsi && !cameraIsMuted && triggerJitsiRender && a.command && ['toggleVideo'].includes(a.command)) {
                        setTriggerJitsiRender(false);
                    } else if (shouldRenderJitsi && !micIsMuted && triggerJitsiRender && a.command && ['toggleAudio'].includes(a.command)) {
                        setTriggerJitsiRender(false);
                    } else if (!shouldRenderJitsi && !triggerJitsiRender && a.command && !['toggleVideo', 'toggleAudio'].includes(a.command)) {
                        return;
                    }

                    a.command && executeCommand(a.command, a.data);
                    a.onCommandSent && a.onCommandSent();
                    a.onSetTopic && a.onSetTopic();
                }}
            />

            {shouldShowFireFoxAlert ? <FirefoxAlert></FirefoxAlert> : null}

            {activeAddOnData && (
                <Stack horizontalAlign="space-between" verticalAlign="center" horizontal style={{ padding: 8, background: theme.palette.themeSecondary }}>
                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                        <img src={activeAddOnData.attributes.iconUrl} height={24} />
                        <Text style={{ color: theme.palette.white }}>{activeAddOnData.attributes.displayName}</Text>
                    </Stack>
                    <IconButton
                        styles={{ root: { color: theme.palette.white } }}
                        onClick={() => {
                            setSelectedAttachment(undefined);
                        }}
                        iconProps={{ iconName: 'clear' }}
                    />
                </Stack>
            )}
            <Stack
                grow
                horizontal
                verticalAlign="stretch"
                style={{
                    border: roundTableBorder,
                    height: '100%'
                }}
            >
                {true && jwt && roomName && domain && (
                    <>
                        {activeAddOnData && (
                            <Stack grow style={{ border: '4px ' + theme.palette.themeSecondary + ' solid' }}>
                                {isSafari && activeAddOnData?.id == 'etherpad' && etherpadAlert ? (
                                    <Stack
                                        verticalAlign="center"
                                        style={{
                                            width: '50vw',
                                            padding: '15px',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Stack
                                            horizontalAlign="center"
                                            verticalAlign="center"
                                            style={{
                                                padding: '16px 26px',
                                                borderRadius: 4,
                                                boxShadow: DefaultEffects.elevation16,
                                                backgroundColor: theme.palette.orangeLighter
                                            }}
                                        >
                                            <IC size={128} variant="light">
                                                <ErrorSymbol />
                                            </IC>
                                            <Text variant="xLarge" style={{ color: '#fff', maxWidth: 700, textAlign: 'justify', marginBottom: 32 }}>
                                                {IvicosStrings.safariRoomCallDescription}
                                                <br />
                                                <br />
                                                {IvicosStrings.safariRoomCallDescriptionStep1}
                                                <br />
                                                {IvicosStrings.safariRoomCallDescriptionStep2}
                                                <br />
                                                {IvicosStrings.safariRoomCallDescriptionStep3}
                                            </Text>
                                            <DefaultButton
                                                text={IvicosStrings.closeActionTitle}
                                                onClick={() => {
                                                    setEtherpadAlert(false);
                                                }}
                                                style={{ marginBottom: 15 }}
                                            />
                                        </Stack>
                                    </Stack>
                                ) : (
                                    <AddOn isHidden={!addOnIsVisible} addOn={activeAddOnData} attachmentRef={selectedAttachment?.ref} />
                                )}
                            </Stack>
                        )}

                        {
                            <React.Fragment>
                                <Stack
                                    id={'bottom-left'}
                                    style={jitsiShowHideContainerStyle}
                                    horizontal={addOnIsVisible}
                                    draggable={addOnIsVisible}
                                    onDragStart={onDragStartHandler}
                                    onDragEnd={onDragEndHandler}
                                >
                                    <Stack style={jitsiShowHideInnerContainerStyle}>
                                        {addOnIsVisible && !isSafari && (
                                            <IconButton
                                                styles={{
                                                    root: { color: theme.palette.white, ':hover': { cursor: 'grab' }, ':active': { cursor: 'grabbing' } }
                                                }}
                                                style={{ zIndex: 5000 }}
                                                iconProps={{ iconName: 'Move' }}
                                            />
                                        )}
                                        {(shouldRenderJitsi || triggerJitsiRender) && (room || roomName.includes('visitor-')) ? (
                                            <Jitsi
                                                loadingComponent={onRenderLoadingComponent}
                                                interfaceConfig={interfaceConfig}
                                                config={newJitsiConfig}
                                                containerStyle={jitsiContainerStyle}
                                                onAPILoad={(api: JitsiMeetAPI) => handleAPILoad(api)}
                                                jwt={jwt}
                                                roomName={roomName}
                                                domain={domain}
                                                displayName={displayName}
                                            />
                                        ) : userIsAloneInOwnPersonalRoom ? (
                                            <Stack style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Persona hidePersonaDetails={true} imageUrl={profileImageURL} coinSize={400} text={displayName}></Persona>
                                            </Stack>
                                        ) : (
                                            <></>
                                        )}
                                    </Stack>
                                    {addOnIsVisible && (
                                        <Stack
                                            style={hideButtonContainerStyle}
                                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                            onClick={(event) => {
                                                setIsVideoHidden((prevState: any) => !prevState);
                                            }}
                                        >
                                            <Icon
                                                iconName={isVideoHidden ? 'ChevronRightSmall' : 'ChevronLeftSmall'}
                                                style={{
                                                    position: 'absolute',
                                                    top: '35%',
                                                    right: '0%',
                                                    fontSize: '20px',
                                                    color: theme.palette.white
                                                }}
                                            />
                                        </Stack>
                                    )}
                                    <StatusIconBar
                                        connected={isConnected}
                                        encryptionEnabled={encryptionIsEnabled}
                                        cameraMuted={cameraIsMuted}
                                        micMuted={micIsMuted}
                                    />
                                </Stack>
                            </React.Fragment>
                        }
                    </>
                )}
                {!smallDevice && !mediumDevice && room && <AttachmentsPanel jitsiAPI={jitsiAPI} />}
                {room && (
                    <SpeakerStats
                        statsAreVisible={statsAreVisible}
                        stats={stats}
                        executeCommand={executeCommand}
                        roundTableBorder={roundTableBorder}
                        setRoundTableBorder={setRoundTableBorder}
                        isLocalUserHandRaised={isLocalUserHandRaised}
                        onHandRaiseDismiss={onHandRaiseDismiss}
                        room={room}
                    />
                )}
            </Stack>
        </CallSurface>
    );
};

export default RoomCallControllerView;
