/* eslint-disable no-useless-escape */
import { ActionButton, Dropdown, IDropdownOption, IDropdownStyles, Stack, Text, TextField } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { idpService } from 'services/api/identity-provider.service';
import { resourceService } from 'services/api/resource.service';
import campusIcon from 'media/img/campusIcon.png';
import campusLogo from 'media/img/ivcampus-logo.svg';
import IvicosStrings from '../kits/language/stringKit';
import { useWindowWidth } from 'shared-state/display-options/hooks';

const IvCAMPUSSetup: React.FC<{}> = () => {
    const routeHistory = useNavigate();

    const [orgName, setOrgName] = useState('');
    const [campusName, setCampusName] = useState('');
    const [campusDomain, setCampusDomain] = useState('');
    const [orgSize, setOrgSize] = useState<'1-25' | '25-50' | '50-100' | '100-500' | 'more than 500'>('1-25');
    const [locationsCount, setLocationsCount] = useState<'1-5' | '5-10' | '10-20' | 'more than 20'>('1-5');
    const [errorMessage2, setErrorMessage2] = useState('');
    const [displayCompanyName, setDisplayCompanyName] = useState(false);
    const [agree, setAgree] = useState(false);
    const [emptyOrgName, setEmptyOrgName] = useState('');
    const [emptyCampusName, setEmptyCampusName] = useState('');
    const [emptyCampusDomain, setEmptyCampusDomain] = useState('');
    const [dropdownChange1, setDropdownChange1] = useState(false);
    const [dropdownChange2, setDropdownChange2] = useState(false);
    const [opacityVal, setOpacityVal] = useState(true);
    const [tickCheckbox, setTickCheckbox] = useState(false);

    function slugify(text: any) {
        return text
            .toString()
            .toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(/[^\w\-]+/g, '') // Remove all non-word chars
            .replace(/\-\-+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, ''); // Trim - from end of text
    }

    const runSetup = async () => {
        try {
            const org = await idpService.createOrg(orgName, orgSize, locationsCount);

            await idpService.authUserToOrg(org.orgId);

            const chosenLang = localStorage.getItem('chosenLanguage') || 'de';
            await resourceService.createCampus('ivicos', org.orgId, campusName, campusDomain, chosenLang);

            window.location.href = '/setupcomplete';
        } catch (error) {
            console.error(error);
        }
    };

    const campusNameHandler = (e: any) => {
        setCampusName(e.target.value);
        setOrgName(e.target.value);
        setDisplayCompanyName(true);
        if (e.target.value === '') {
            setDisplayCompanyName(false);
        }

        setCampusDomain(slugify(e.target.value));
        let error = '';
        if (e.target.value.length < 3) {
            error = IvicosStrings.min3;
            tickCheckbox && setTickCheckbox(false);
            !opacityVal && setOpacityVal(true);
        } else if (e.target.value.length > 71) {
            error = IvicosStrings.max7;
        }
        setErrorMessage2(error);
    };

    const orgSizeHandler = (e: any) => {
        setOrgSize(e.currentTarget.innerText);
        setDropdownChange1(true);
    };
    const locationsCountHandler = (e: any) => {
        setLocationsCount(e.currentTarget.innerText);
        setDropdownChange2(true);
    };

    const onSubmit = (event: any) => {
        event.preventDefault();
        runSetup();
    };

    const DropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 300 },
        callout: { maxHeight: 200, overflowY: 'scroll' }
    };
    const option: IDropdownOption[] = [
        { key: 1, text: '1-25' },
        { key: 2, text: '25-50' },
        { key: 3, text: '50-100' },
        { key: 4, text: '100-500' },
        { key: 5, text: IvicosStrings.moreThan500 }
    ];
    const option2: IDropdownOption[] = [
        { key: 1, text: '1-5' },
        { key: 2, text: '5-10' },
        { key: 3, text: '10-20' },
        { key: 4, text: IvicosStrings.moreThan20 }
    ];

    useEffect(() => {
        if (tickCheckbox && orgName.length > 2 && dropdownChange1 && dropdownChange2) {
            setAgree(true);
        } else {
            setAgree(false);
        }
    }, [agree, dropdownChange1, dropdownChange2, orgName.length, tickCheckbox]);

    useEffect(() => {
        if (agree) {
            setOpacityVal(false);
        } else {
            setOpacityVal(true);
        }
    }, [agree]);

    const styleForTextField = {
        root: [
            {
                width: 308
            }
        ]
    };

    const { smallDevice, mediumDevice } = useWindowWidth();

    const onClick = () => {
        routeHistory('/welcome');
    };

    return (
        <Stack>
            <ActionButton
                iconProps={{ iconName: 'Back' }}
                text={IvicosStrings.back}
                onClick={onClick}
                style={{
                    marginLeft: smallDevice ? 20 : mediumDevice ? 100 : 200,
                    marginTop: 50,
                    width: 'fit-content'
                }}
            />
            <Stack
                style={{
                    backgroundColor: '#e4e4e3',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: -100
                }}
            >
                <Stack style={{ marginBottom: 53, width: 375, height: 73 }}>
                    <img src={campusLogo} alt="logo" />
                </Stack>

                <Stack style={{ marginBottom: -50, zIndex: 10 }}>
                    <img src={campusIcon} alt="campus icon" width={91.56} />
                </Stack>

                <Stack
                    style={{
                        height: errorMessage2 !== '' ? 525 : 500,
                        width: 375,
                        backgroundColor: '#fff',
                        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
                    }}
                >
                    <Stack
                        style={{
                            height: errorMessage2 !== '' ? 450 : 425,
                            width: 375
                        }}
                    >
                        <Stack
                            style={{
                                margin: '50px auto 0 auto'
                            }}
                        >
                            <Text variant="xxLarge" style={{ margin: '10px auto 30px auto', fontWeight: 'bold' }}>
                                {IvicosStrings.ivCAMPUSSetupPageTitle}
                            </Text>
                            <form
                                onSubmit={(event) => {
                                    onSubmit(event);
                                }}
                            >
                                <Stack>
                                    <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>{IvicosStrings.nameLabel}</Text>
                                    <TextField
                                        onChange={(e) => {
                                            campusNameHandler(e);
                                            setEmptyOrgName('');
                                        }}
                                        styles={styleForTextField}
                                        maxLength={72}
                                        placeholder={campusName === '' ? IvicosStrings.organisationNamePlaceholder : orgName}
                                        data-test-id="orgName"
                                        errorMessage={emptyOrgName}
                                    />
                                    <Text style={{ color: 'red' }}>{errorMessage2}</Text>
                                </Stack>
                                <br></br>
                                <Text style={{ fontWeight: 'bold' }}>{IvicosStrings.sizeOfTheOrganisationLabel}</Text>
                                <Dropdown
                                    onChange={(e) => {
                                        orgSizeHandler(e);
                                        setEmptyCampusName('');
                                    }}
                                    placeholder={IvicosStrings.sizeOfTheOrganisationPlaceholder}
                                    options={option}
                                    styles={DropdownStyles}
                                    style={{ width: 308, marginTop: 5 }}
                                    aria-label="dropdown1"
                                    errorMessage={emptyCampusName}
                                />
                                <br></br>
                                <Text style={{ fontWeight: 'bold' }}>{IvicosStrings.locationsLabel}</Text>
                                <Dropdown
                                    onChange={(e) => {
                                        locationsCountHandler(e);
                                        setEmptyCampusDomain('');
                                    }}
                                    placeholder={IvicosStrings.howManyLocationsPlaceholder}
                                    options={option2}
                                    styles={DropdownStyles}
                                    style={{ width: 308, marginTop: 5 }}
                                    aria-label="dropdown2"
                                    errorMessage={emptyCampusDomain}
                                />
                                <br></br>
                                <Stack style={{ flexDirection: 'row' }}>
                                    <input
                                        style={{ width: 20, height: 30 }}
                                        type="checkbox"
                                        onChange={() => {
                                            setTickCheckbox(!tickCheckbox);
                                        }}
                                        checked={tickCheckbox}
                                    />
                                    {displayCompanyName ? (
                                        <Stack
                                            style={{
                                                width: 280,
                                                height: 60,
                                                textAlign: 'justify'
                                            }}
                                        >
                                            <Text aria-label="setup-checkbox" style={{ marginTop: 8, marginLeft: 5 }} variant="small">
                                                {IvicosStrings.ivCAMPUSSetupPageCheckBoxDescription1} {campusName}
                                                {IvicosStrings.ivCAMPUSSetupPageCheckBoxDescription3}.
                                            </Text>
                                        </Stack>
                                    ) : (
                                        <Stack
                                            style={{
                                                width: 280,
                                                height: 60,
                                                textAlign: 'justify'
                                            }}
                                        >
                                            <Text style={{ marginTop: 8, marginLeft: 5 }} variant="small">
                                                {IvicosStrings.ivCAMPUSSetupPageCheckBoxDescription2}
                                            </Text>
                                        </Stack>
                                    )}
                                </Stack>
                                <button
                                    type="submit"
                                    style={{
                                        border: 'none',
                                        cursor: 'pointer',
                                        width: 308,
                                        height: 38,
                                        marginTop: 20,
                                        backgroundColor: '#172D4C',
                                        borderRadius: 5,
                                        opacity: opacityVal ? 0.2 : 1
                                    }}
                                    disabled={!agree}
                                >
                                    <Text
                                        style={{
                                            color: '#fff',
                                            cursor: 'pointer',
                                            fontWeight: 'bold'
                                        }}
                                        variant="large"
                                    >
                                        {IvicosStrings.finish}
                                    </Text>
                                </button>
                            </form>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default IvCAMPUSSetup;
