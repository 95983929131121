import { DefaultButton, Dialog, DialogFooter, DialogType, IconButton, Modal, PrimaryButton, Stack, Text } from '@fluentui/react';
import { ICButton } from 'components/SVGIcon';
import { IMember } from 'features/CampusSettings/CampusSettings.state';
import { Resendmail, Settings, Trash } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useCampus, useReviewInvitations } from 'shared-state/directory/hooks';

export interface IMemberDialogs {
    emailToInvite: string;
    setEmailToInvite: React.Dispatch<React.SetStateAction<string>>;
    inviteUser: () => void;
    reInviteUser: () => void;
    deleteUser: () => Promise<void>;
    setUser: React.Dispatch<React.SetStateAction<IMember | undefined>>;
    setUserToDelete: React.Dispatch<React.SetStateAction<IMember | undefined>>;
    userForMobileModal: IMember | undefined;
    openEditUserPanel: () => void;
    togglehideInviteDialog: () => void;
    togglehideUserExistsDialog: () => void;
    togglehideReinviteDialog: () => void;
    togglehideDeleteDialog: () => void;
    togglehideNoRoleEnteredDialog: () => void;
    hideInviteDialog: boolean;
    hideDeleteDialog: boolean;
    hideReinviteDialog: boolean;
    hideUserExistsDialog: boolean;
    hideNoRoleEnteredDialog: boolean;
    isModalOpen: boolean;
    hideModal: () => void;
    isUserFromOtherOrgBillingList: boolean;
    setIsUserFromOtherOrgBillingList: React.Dispatch<React.SetStateAction<boolean>>;
    hideAddAnyoneToYourBillingList: boolean;
    toggleHideAddAnyoneToYourBillingList: () => void;
}

const MemberDialogs: React.FC<IMemberDialogs> = ({
    emailToInvite,
    setEmailToInvite,
    inviteUser,
    reInviteUser,
    deleteUser,
    setUser,
    setUserToDelete,
    userForMobileModal,
    openEditUserPanel,
    togglehideInviteDialog,
    togglehideUserExistsDialog,
    togglehideReinviteDialog,
    togglehideDeleteDialog,
    togglehideNoRoleEnteredDialog,
    hideDeleteDialog,
    hideInviteDialog,
    hideReinviteDialog,
    hideUserExistsDialog,
    hideNoRoleEnteredDialog,
    isModalOpen,
    hideModal,
    isUserFromOtherOrgBillingList,
    setIsUserFromOtherOrgBillingList,
    hideAddAnyoneToYourBillingList,
    toggleHideAddAnyoneToYourBillingList
}) => {
    const inviteDialogContentProps = {
        type: DialogType.normal,
        title: IvicosStrings.invitationSentNotificationActionTitle,
        closeButtonAriaLabel: IvicosStrings.closeActionTitle,
        subText: IvicosStrings.dialogYourInvitationSent
    };

    const inactiveAccountInviteDialogContentProps = {
        type: DialogType.normal,
        title: IvicosStrings.dialogPersonDoesNotHaveActiveAccountTitle,
        closeButtonAriaLabel: IvicosStrings.closeActionTitle
    };

    const reinviteDialogContentProps = {
        type: DialogType.normal,
        title: IvicosStrings.resendInvitationActionTitle,
        closeButtonAriaLabel: IvicosStrings.closeActionTitle,
        subText: IvicosStrings.resendInvitationActionDescription + emailToInvite + '?'
    };

    const reviewInviteDialogContentProps = {
        type: DialogType.normal,
        title: IvicosStrings.reviewInvitationTitle,
        closeButtonAriaLabel: IvicosStrings.closeActionTitle
    };

    const userAlreadyExistsContentProps = {
        type: DialogType.normal,
        title: 'User already exists',
        closeButtonAriaLabel: IvicosStrings.closeActionTitle,
        subText: emailToInvite + IvicosStrings.dialogIsAlreadyAMember
    };

    const deleteDialogContentProps = {
        type: DialogType.normal,
        title: IvicosStrings.deleteInvitationActionTitle2,
        closeButtonAriaLabel: IvicosStrings.closeActionTitle,
        subText: IvicosStrings.deleteInvitationActionDescription
    };

    const noRoleEnteredDialogContentProps = {
        type: DialogType.normal,
        title: 'Missing role assignment',
        closeButtonAriaLabel: 'Close',
        subText: emailToInvite + ' was not assigned any role'
    };

    const onDismiss = () => {
        hideModal();
    };

    const [campus] = useCampus();
    const [isReviewInvitationsModalClose, setIsReviewInvitationsModalClose] = useReviewInvitations();

    return (
        <>
            <Dialog
                hidden={hideInviteDialog}
                onDismiss={() => {
                    togglehideInviteDialog();
                    setIsUserFromOtherOrgBillingList(false);
                    setEmailToInvite('');
                }}
                dialogContentProps={inviteDialogContentProps}
            >
                {isUserFromOtherOrgBillingList && (
                    <Text>
                        {IvicosStrings.dialogPersonNotAddedToBillingList1}
                        {campus.name}
                        {IvicosStrings.dialogPersonNotAddedToBillingList2}
                    </Text>
                )}

                <DialogFooter>
                    <PrimaryButton
                        aria-label="close-invitation-confirmation-dialog"
                        onClick={() => {
                            togglehideInviteDialog();
                            setIsUserFromOtherOrgBillingList(false);
                            setEmailToInvite('');
                        }}
                        text={IvicosStrings.closeActionTitle}
                        className={'close-dialog'}
                    />
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={hideAddAnyoneToYourBillingList}
                onDismiss={() => {
                    toggleHideAddAnyoneToYourBillingList();
                    setEmailToInvite('');
                }}
                dialogContentProps={inactiveAccountInviteDialogContentProps}
            >
                <Text>
                    {IvicosStrings.dialogPersonDoesNotHaveActiveAccount1}
                    <br />
                    <br />
                    {IvicosStrings.dialogPersonDoesNotHaveActiveAccount2}
                </Text>

                <DialogFooter>
                    <DefaultButton
                        onClick={() => {
                            toggleHideAddAnyoneToYourBillingList();
                            setEmailToInvite('');
                        }}
                        text={IvicosStrings.dialogPersonDoesNotHaveActiveAccountNo}
                    />
                    <PrimaryButton
                        onClick={() => {
                            inviteUser();
                            toggleHideAddAnyoneToYourBillingList();
                            setEmailToInvite('');
                        }}
                        text={IvicosStrings.dialogPersonDoesNotHaveActiveAccountYes}
                        className={'close-dialog'}
                    />
                </DialogFooter>
            </Dialog>

            <Dialog
                hidden={isReviewInvitationsModalClose}
                onDismiss={() => {
                    setIsReviewInvitationsModalClose(true);
                    window.location.reload();
                    setEmailToInvite('');
                }}
                dialogContentProps={reviewInviteDialogContentProps}
            >
                <Text>
                    {IvicosStrings.reviewInvitationDescription1}
                    <br />
                    <br />
                    {IvicosStrings.reviewInvitationDescription2}
                </Text>

                <DialogFooter>
                    <PrimaryButton
                        aria-label="close-invitation-confirmation-dialog"
                        onClick={() => {
                            setIsReviewInvitationsModalClose(true);
                            window.location.reload();
                        }}
                        text={IvicosStrings.reviewInvitationClose}
                        className={'close-dialog'}
                    />
                </DialogFooter>
            </Dialog>

            <Dialog
                hidden={hideReinviteDialog}
                onDismiss={() => {
                    togglehideReinviteDialog();
                    setEmailToInvite('');
                }}
                dialogContentProps={reinviteDialogContentProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            reInviteUser();
                            togglehideReinviteDialog();
                        }}
                        text={IvicosStrings.confirmActionTitle}
                    />
                    <DefaultButton
                        onClick={() => {
                            togglehideReinviteDialog();
                            setEmailToInvite('');
                        }}
                        text={IvicosStrings.cancelActionTitle}
                    />
                </DialogFooter>
            </Dialog>

            <Dialog
                hidden={hideUserExistsDialog}
                onDismiss={() => {
                    togglehideUserExistsDialog();
                    setEmailToInvite('');
                }}
                dialogContentProps={userAlreadyExistsContentProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            togglehideUserExistsDialog();
                            setEmailToInvite('');
                        }}
                        text={IvicosStrings.closeActionTitle}
                    />
                </DialogFooter>
            </Dialog>

            {/* NO ROLE ENTERED */}

            <Dialog
                hidden={hideNoRoleEnteredDialog}
                onDismiss={() => {
                    togglehideNoRoleEnteredDialog();
                    // setEmailToInvite('');
                }}
                dialogContentProps={noRoleEnteredDialogContentProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            togglehideNoRoleEnteredDialog();
                            // setEmailToInvite('');
                        }}
                        text={'Close'}
                    />
                </DialogFooter>
            </Dialog>

            <Dialog
                hidden={hideDeleteDialog}
                onDismiss={() => {
                    togglehideDeleteDialog();
                    setEmailToInvite('');
                }}
                dialogContentProps={deleteDialogContentProps}
            >
                <DialogFooter>
                    <DefaultButton onClick={() => togglehideDeleteDialog()} text={IvicosStrings.cancelActionTitle} />
                    <PrimaryButton
                        className="confirm-member-delete"
                        onClick={() => {
                            togglehideDeleteDialog();
                            deleteUser();
                        }}
                        text={IvicosStrings.confirmActionTitle}
                    />
                </DialogFooter>
            </Dialog>

            <Modal isOpen={isModalOpen} onDismiss={onDismiss} isBlocking={false}>
                <Stack horizontal horizontalAlign="end">
                    <IconButton iconProps={{ iconName: 'cancel' }} ariaLabel="Close popup modal" onClick={onDismiss} />
                </Stack>
                <Stack horizontalAlign={'center'} style={{ paddingBottom: '30px' }}>
                    <Text variant="xLarge">{userForMobileModal?.name}</Text>
                    <Text variant="medium">{userForMobileModal?.email}</Text>
                </Stack>
                <Stack horizontalAlign={'center'} horizontal tokens={{ childrenGap: 32 }}>
                    <ICButton
                        variant={'rect'}
                        onClick={() => {
                            togglehideDeleteDialog();
                            setUserToDelete(userForMobileModal);
                            hideModal();
                        }}
                    >
                        <Trash />
                    </ICButton>

                    {userForMobileModal?.userId != 'invitee' && userForMobileModal?.role != 'owner' ? (
                        <ICButton
                            variant={'rect'}
                            onClick={() => {
                                setUser(userForMobileModal);
                                openEditUserPanel();
                                hideModal();
                            }}
                        >
                            <Settings />
                        </ICButton>
                    ) : (
                        <ICButton
                            variant={'rect'}
                            onClick={() => {
                                setEmailToInvite(userForMobileModal.email);
                                togglehideReinviteDialog();
                                hideModal();
                            }}
                        >
                            <Resendmail />
                        </ICButton>
                    )}
                </Stack>
            </Modal>
        </>
    );
};

export default MemberDialogs;
