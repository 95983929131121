import { Stack } from '@fluentui/react';
import { IdentityAdapter } from 'adapters/identity-adapter/identity-adapter';
import { ResourceAdapter } from 'adapters/resource-adapter/resource-adapter';
import TrialPeriodExpired from 'components/TrialPeriodExpired/TrialPeriodExpired';
import LoadingDialog from 'components/LoadingDialog/LoadingDialog';
import AreaSwitch from 'features/Areas/AreaSwitch/AreaSwitch';
import { ConnectivityModal } from 'features/ConnectivityModal/ConnectivityModal';
import IvicosStrings from 'kits/language/stringKit';
import React, { useEffect, useState } from 'react';
import CoreView from 'routes/Core/Core.view';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import SocketProvider from 'services/socket-connection/context/SocketProvider';
import SocketAdapter from 'services/socket-connection/socket-adapters';

const Core: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [identity] = useIdentityClaims();
    console.log('IDENTITY', identity);

    const [isLoadingDialogVisible, setIsLoadingDialogVisible] = useState(true);
    const [isOrgStandby, setIsOrgStandby] = useState(['standby'].includes(identity?.orgStatus));

    useEffect(() => {
        setIsOrgStandby(['standby'].includes(identity?.orgStatus));
    }, [identity]);

    const clean = () => {
        setTimeout(() => {
            setIsLoadingDialogVisible(false);
        }, 1000);
    };

    clean();

    const onRenderTopBarDocumentPicker = (textColor?: string) => (identity ? <AreaSwitch color={textColor} /> : <></>);

    return (
        <Stack style={{ height: '100vh' }}>
            {isLoadingDialogVisible && <LoadingDialog loadingStrings={[IvicosStrings.loadingTitle]} view="full" typewriter={false} />}
            <IdentityAdapter />
            {isOrgStandby ? (
                <TrialPeriodExpired identity={identity} />
            ) : (
                <>
                    <ResourceAdapter />
                    <ConnectivityModal />
                    <SocketProvider>
                        <SocketAdapter />
                        <CoreView onRenderTopBarDocumentPicker={onRenderTopBarDocumentPicker}>{children}</CoreView>
                    </SocketProvider>
                </>
            )}
        </Stack>
    );
};

export default Core;
