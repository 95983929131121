/* eslint-disable react/react-in-jsx-scope */
import { Persona, PersonaSize } from '@fluentui/react';
import Badge from 'components/Badge';
import { InfoBubble } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import { useMemo } from 'react';
import { useCrossAreaUser, useUser } from 'shared-state/presence/hooks';
import { aIsUserOutOfCampus, aIsUserSwitchedArea } from './atoms';
import { useRecoilValue } from 'recoil';

export const useNotificationGroupedAssets = (senderId: string, typeOfMessage: string[], numOfMessages: number): any => {
    const _sender = useUser(senderId);
    const crossAreaSender = useCrossAreaUser(senderId);
    const sender = useMemo(() => _sender, [_sender]);
    const isUserSwitchedArea = useRecoilValue(aIsUserSwitchedArea);
    const isUserOutOfCampus = useRecoilValue(aIsUserOutOfCampus);

    const getInitialsFromDisplayName = (displayName: string): string => {
        const splitName = displayName.split(' ');
        return splitName.length > 1 ? `${splitName[0][0].toUpperCase()}${splitName[1][0].toUpperCase()}` : splitName[0][0].toUpperCase();
    };

    const getNotificationIcon = () => {
        if (typeOfMessage.includes('memo') || typeOfMessage.includes('invite') || typeOfMessage.includes('knock')) {
            return (
                <Persona
                    imageUrl={isUserSwitchedArea[senderId] ? '' : sender?.profileImage || ''}
                    imageInitials={getInitialsFromDisplayName(
                        isUserSwitchedArea[senderId] ? IvicosStrings.someoneMessage : sender?.displayName || IvicosStrings.someoneMessage
                    )}
                    size={PersonaSize.size40}
                    imageAlt={isUserSwitchedArea[senderId] ? IvicosStrings.someoneMessage : sender?.displayName || IvicosStrings.someoneMessage}
                />
            );
        }

        if (typeOfMessage.includes('cross-area-memo') || typeOfMessage.includes('cross-area-invite')) {
            return (
                <Persona
                    imageUrl={isUserOutOfCampus[senderId] ? '' : crossAreaSender?.profileImage || ''}
                    imageInitials={getInitialsFromDisplayName(
                        isUserOutOfCampus[senderId] ? IvicosStrings.someoneMessage : crossAreaSender?.displayName || IvicosStrings.someoneMessage
                    )}
                    size={PersonaSize.size40}
                    imageAlt={isUserOutOfCampus[senderId] ? IvicosStrings.someoneMessage : crossAreaSender?.displayName || IvicosStrings.someoneMessage}
                />
            );
        }

        if (typeOfMessage.includes('guest')) {
            return <Badge text={'visitor'} size="41" fontSize={'medium'} textColor="#000" />;
        }

        return <InfoBubble />;
    };

    const getNotificationTitle = () => {
        if (typeOfMessage.includes('memo') || typeOfMessage.includes('invite') || typeOfMessage.includes('knock'))
            return `${
                isUserSwitchedArea[senderId]
                    ? IvicosStrings.someoneMessage
                    : sender?.displayName || crossAreaSender?.displayName || IvicosStrings.someoneMessage
            } ${IvicosStrings.seekingAttention}`;

        if (typeOfMessage.includes('cross-area-memo') || typeOfMessage.includes('cross-area-invite'))
            return `${
                isUserOutOfCampus[senderId] ? IvicosStrings.someoneMessage : sender?.displayName || crossAreaSender?.displayName || IvicosStrings.someoneMessage
            } ${IvicosStrings.seekingAttention}`;

        if (typeOfMessage.includes('guest')) return IvicosStrings.thereAreVisitors;
        return 'New notification';
    };

    const getNotificationDescription = () => {
        const attentionTypesNotCross = ['memo', 'invite', 'knock'];
        const attentionTypesCross = ['cross-area-memo', 'cross-area-invite'];

        if (attentionTypesNotCross.some((type) => typeOfMessage.includes(type))) {
            return `${isUserSwitchedArea[senderId] ? IvicosStrings.someoneMessage : sender?.displayName || IvicosStrings.someoneMessage} ${
                isUserSwitchedArea[senderId] ? IvicosStrings.wasSeekingAttention : IvicosStrings.seekingAttention
            }`;
        }

        if (attentionTypesCross.some((type) => typeOfMessage.includes(type))) {
            return `${isUserOutOfCampus[senderId] ? IvicosStrings.someoneMessage : crossAreaSender?.displayName || IvicosStrings.someoneMessage} ${
                isUserOutOfCampus[senderId] ? IvicosStrings.wasSeekingAttention : IvicosStrings.seekingAttention
            }`;
        }

        if (typeOfMessage.includes('guest')) {
            return `${IvicosStrings.youHave} ${numOfMessages} ${IvicosStrings.visitorsWaiting}`;
        }

        return '';
    };

    const description = getNotificationDescription();
    const title = getNotificationTitle();

    return { getNotificationIcon, title, description, typeOfMessage };
};
