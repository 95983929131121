import {
    Callout,
    ChoiceGroup,
    DefaultButton,
    DefaultEffects,
    DelayedRender,
    DirectionalHint,
    mergeStyleSets,
    Modal,
    Pivot,
    PivotItem,
    PrimaryButton,
    ScrollablePane,
    Stack,
    Text,
    Toggle
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import DynamicForm from 'components/DynamicForm/DynamicForm';
import { IC } from 'components/SVGIcon';
import 'intro.js/introjs.css';
import { sendEvent } from 'kits/eventKit';
import { InfoBubble, QuestionCircle, Shape } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import 'styles/introjs-custom.css';
import { getClassNames } from 'styles/responsiveness';
import CampusSettingsIntro from './CampusSettings.intro';
import { ICampusSettingsState } from './CampusSettings.state';

const CampusSettingsView: React.FC<ICampusSettingsState> = (props) => {
    const {
        microsoftProps,
        successModal,
        setSuccessModal,
        isFormModalOpen,
        showFormModal,
        hideFormModal,
        titleId,
        colorButtonId,
        campus,
        onCampusFormChange,
        onUpdateCampus,
        setChanges,
        changes,
        formFieldsCampus,
        theme,
        fetchCampus,
        pivotDisplaySmallDevice,
        handleLinkClick,
        selectedKey,
        formFieldsVisitors,
        onVisitorFormChange,
        onUpdateVisitor,
        setRemoveOrgOpened,
        removeOrgOpened,
        performActionRemoveOrg,
        removeOrgOptions,
        setRemoveOrgChoice
    } = props;

    function _onMsLinkToggle(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        if (checked) {
            confirm(IvicosStrings.confirmDescription) === true ? microsoftProps.setMicrosoftTenantIsInvited(true) : !checked;
        }
        return microsoftProps.setMicrosoftTenantIsInvited(false);
    }

    const directionalHint = DirectionalHint.bottomCenter;

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);

    const msButtonId = useId('callout-button');

    const styles = mergeStyleSets({
        button: {
            width: 130
        },
        callout: {
            width: 320,
            padding: '20px 24px'
        }
    });

    const { settingsGeneralCard } = getClassNames();
    const { smallDevice, mediumDevice, smallLaptop } = useWindowWidth();

    return (
        <>
            {smallDevice && (
                <Stack style={{ padding: '26px 0px 13px 33px' }}>
                    <Text variant="large">{IvicosStrings.campusSettingsTitle}</Text>
                </Stack>
            )}
            <Stack grow style={{ position: 'relative', height: 'calc( 80vh - 140px )' }}>
                <ScrollablePane style={{ height: '100%', minHeight: '100%', margin: '0px 30px' }}>
                    <Stack horizontal={!smallDevice && !mediumDevice && !smallLaptop} tokens={{ childrenGap: 48 }} verticalAlign="start">
                        <Stack aria-label="brand-identity-component" tokens={{ childrenGap: 16 }} className={`${settingsGeneralCard} display-settings`}>
                            <Text variant={smallDevice ? 'large' : 'xLarge'} style={{ color: theme.palette.black }}>
                                {IvicosStrings.brandIdentityTitle}
                            </Text>

                            <Stack horizontal horizontalAlign="start" style={{ padding: 2 }} className={pivotDisplaySmallDevice}>
                                <Pivot selectedKey={selectedKey} onLinkClick={handleLinkClick} headersOnly={true} className={`${pivotDisplaySmallDevice} menu`}>
                                    <PivotItem
                                        headerText={IvicosStrings.campusSettingsTitle}
                                        itemKey="campus"
                                        className="one-tab"
                                        style={{ width: '30px', height: '20px' }}
                                    />
                                    <PivotItem headerText={IvicosStrings.visitorEntranceTitle} itemKey="visitors" />
                                </Pivot>
                            </Stack>

                            {selectedKey == 'campus' ? (
                                <DynamicForm formFields={formFieldsCampus} onChange={onCampusFormChange} />
                            ) : (
                                <DynamicForm formFields={formFieldsVisitors} onChange={onVisitorFormChange} />
                            )}

                            <Stack horizontalAlign="end" horizontal style={{ gap: '15px' }}>
                                <DefaultButton
                                    text={IvicosStrings.removeOrganizationButton}
                                    iconProps={{ iconName: 'Delete' }}
                                    onClick={() => setRemoveOrgOpened(true)}
                                />
                                <PrimaryButton
                                    text={IvicosStrings.saveActionTitle}
                                    iconProps={{ iconName: 'Save' }}
                                    onClick={() => {
                                        setSuccessModal(false);
                                        showFormModal();
                                    }}
                                />
                            </Stack>
                        </Stack>
                        {microsoftProps.isMicrosoftUser && (
                            <Stack
                                style={{
                                    width: smallDevice ? '100%' : 380,
                                    background: theme.palette.white,
                                    padding: '16px 26px',
                                    borderRadius: 2,
                                    boxShadow: DefaultEffects.elevation4
                                }}
                            >
                                <Stack horizontal={false} tokens={{ childrenGap: 16 }}>
                                    <Text variant={smallDevice ? 'large' : 'xLarge'} style={{ color: theme.palette.black }}>
                                        {IvicosStrings.accessMethodTitle}
                                    </Text>
                                    {microsoftProps.isMicrosoftUser && (
                                        <Stack>
                                            <Stack horizontal horizontalAlign="space-around">
                                                <Toggle onChange={_onMsLinkToggle} checked={microsoftProps.microsoftTenantIsInvited} />
                                                <Stack style={{ width: '100%', paddingLeft: '10px' }}>
                                                    {microsoftProps.microsoftTenantIsInvited ? (
                                                        <Text
                                                            variant="medium"
                                                            style={
                                                                smallDevice
                                                                    ? { width: '100%', color: theme.palette.black }
                                                                    : { width: '75%', color: theme.palette.black }
                                                            }
                                                        >
                                                            {IvicosStrings.switchOffDescription}
                                                        </Text>
                                                    ) : (
                                                        <Text
                                                            variant="medium"
                                                            style={
                                                                smallDevice
                                                                    ? { width: '100%', color: theme.palette.black }
                                                                    : { width: '70%', color: theme.palette.black }
                                                            }
                                                        >
                                                            {IvicosStrings.switchOnDescription}
                                                        </Text>
                                                    )}
                                                </Stack>
                                                {!smallDevice && (
                                                    <Stack verticalAlign="center" style={{ width: '20px' }}>
                                                        <IC variant="custom" size={15} style={{ cursor: 'pointer' }}>
                                                            <QuestionCircle onClick={toggleIsCalloutVisible} id={msButtonId} />
                                                        </IC>
                                                    </Stack>
                                                )}
                                                {isCalloutVisible && (
                                                    <Callout
                                                        className={styles.callout}
                                                        onDismiss={toggleIsCalloutVisible}
                                                        role="status"
                                                        target={`#${msButtonId}`}
                                                        aria-live="assertive"
                                                        directionalHint={directionalHint}
                                                    >
                                                        <DelayedRender>
                                                            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="start">
                                                                <IC size={24} variant="dark">
                                                                    <InfoBubble />
                                                                </IC>
                                                                <Stack>
                                                                    <Text variant="medium" style={{ marginBottom: 5 }}>
                                                                        {IvicosStrings.accessMethodCallout1}{' '}
                                                                    </Text>
                                                                    <Text variant="medium">{IvicosStrings.accessMethodCallout2}</Text>
                                                                </Stack>
                                                            </Stack>
                                                        </DelayedRender>
                                                    </Callout>
                                                )}
                                            </Stack>
                                        </Stack>
                                    )}
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                </ScrollablePane>
            </Stack>
            <CampusSettingsIntro />

            <Modal isOpen={isFormModalOpen} onDismiss={hideFormModal} isBlocking={false} titleAriaId={titleId}>
                {successModal ? (
                    <>
                        <Stack
                            tokens={{ childrenGap: 17 }}
                            style={{ justifyContent: 'center', alignContent: 'center', padding: '20px', width: '330px', height: '110px' }}
                        >
                            <Stack horizontal horizontalAlign="space-around" style={{ width: '60%' }}>
                                <IC size={20} variant="custom">
                                    <Shape id={colorButtonId} />
                                </IC>
                                <Text variant="xLarge" style={{ marginLeft: '10px' }}>
                                    {IvicosStrings.changesSavedActionTitle}
                                </Text>
                            </Stack>
                        </Stack>
                        <Stack horizontal verticalAlign="center" horizontalAlign="end" tokens={{ childrenGap: 7 }} style={{ padding: '20px' }}>
                            <PrimaryButton
                                aria-label="btn-to-close-saved-changes"
                                text={IvicosStrings.closeActionTitle}
                                onClick={() => {
                                    setChanges({});
                                    hideFormModal();
                                    setSuccessModal(false);
                                    sendEvent('refetchBranding', {});
                                    fetchCampus();
                                }}
                            />
                        </Stack>
                    </>
                ) : (
                    <>
                        <Stack
                            tokens={{ childrenGap: 17 }}
                            style={{ justifyContent: 'center', alignContent: 'center', padding: '20px', width: '330px', height: '110px' }}
                        >
                            <Text variant="xLarge">{IvicosStrings.saveChangesActionTitle}?</Text>
                            <Text>{IvicosStrings.saveCampusSettingsDescription}</Text>
                        </Stack>
                        <Stack horizontal verticalAlign="center" horizontalAlign="end" tokens={{ childrenGap: 7 }} style={{ padding: '20px' }}>
                            <DefaultButton text={IvicosStrings.cancelActionTitle} onClick={hideFormModal} />
                            <PrimaryButton
                                aria-label="btn-to-save-changes-in-settings"
                                text={IvicosStrings.confirmActionTitle}
                                onClick={async () => {
                                    {
                                        setSuccessModal(true);
                                        if (!campus) return;
                                        if (changes && Object.keys(changes).length > 0) {
                                            (await onUpdateVisitor(changes)) ||
                                                (onUpdateCampus &&
                                                    onUpdateCampus(campus.orgId, changes).then(() => {
                                                        sendEvent('refetchBranding', {});
                                                        setTimeout(() => {
                                                            hideFormModal();
                                                        }, 1000);
                                                    }));

                                            return;
                                        }
                                    }
                                }}
                            />
                        </Stack>
                    </>
                )}
            </Modal>
            <Modal isBlocking isOpen={removeOrgOpened} onDismiss={() => setRemoveOrgOpened(false)}>
                <Stack
                    horizontalAlign="center"
                    style={{ maxWidth: 400, padding: 26, alignItems: 'start' }}
                    tokens={{ childrenGap: 32 }}
                    aria-label="remove-org-modal"
                >
                    <Stack horizontalAlign="center" tokens={{ childrenGap: 6 }} style={{ alignItems: 'start', gap: '20px' }}>
                        <Text variant="xLarge">{IvicosStrings.removeOrganizationModalTitle}</Text>
                        <Text>{IvicosStrings.removeOrganizationQuestion.replace('[org]', campus ? campus.name : 'this organisation')}</Text>
                        <Text>{IvicosStrings.removeOrganizationExplanation}</Text>
                        <ChoiceGroup
                            defaultSelectedKey={'refuse'}
                            options={removeOrgOptions}
                            onChange={(e, option) => {
                                e?.preventDefault;
                                setRemoveOrgChoice(option?.key);
                            }}
                            label={IvicosStrings.trialPeriodExpiredChoiceTitle}
                            aria-label="remove-org-choices"
                            required
                        />
                    </Stack>
                    <PrimaryButton
                        text={IvicosStrings.okActionTitle}
                        onClick={performActionRemoveOrg}
                        style={{ alignSelf: 'end' }}
                        aria-label="remove-org-ok-button"
                    />
                </Stack>
            </Modal>
        </>
    );
};

export default CampusSettingsView;
