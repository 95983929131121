import { atom, atomFamily } from 'recoil';
import { useLocalOptionsAtomArrayEffects, useLocalOptionsAtomEffects } from 'shared-state/display-options/effects';
import { getOptionArrayLocally } from 'shared-state/display-options/helpers';
import { INotificationPositionOption } from './types';

export const aSideBarDraggableWidth = atom<number>({ key: 'aSideBarDraggableWidth', default: 0 });
export const aControlNameWidthInMemo = atom<boolean>({ key: 'aControlNameWidthInMemo', default: false });
export const aDirectControlNameWidthInMemo = atom<boolean>({ key: 'aDirectControlNameWidthInMemo', default: false });
export const aControlTogglePanelState = atom<boolean>({ key: 'aControlTogglePanelState', default: true });
export const aSideBarIsMaximized = atom<boolean>({ key: 'aSideBarIsMaximized', default: true });
export const aShowAreaPanel = atom<boolean>({ key: 'aShowAreaPanel', default: false });
export const aShowSearchBar = atom<boolean>({ key: 'aShowSearchBar', default: false });
export const aCrossAreaMessageType = atom<boolean>({ key: 'aCrossAreaMessageType', default: false });
export const aCrossAreaSearchBarInputValue = atom<string>({ key: 'aCrossAreaSearchBarInputValue', default: '' });

export const aHelpModalIsOpen = atom<boolean>({ key: 'aHelpModalIsOpen', default: false });

export const aShowCrossAreaMessagePanel = atom<boolean>({ key: 'aShowCrossAreaMessagePanel', default: false });
export const aIsMemoOpenOnCrossAreaMessagePanel = atom<boolean>({ key: 'aIsMemoOpenOnCrossAreaMessagePanel', default: false });
export const aIsCrossAreaUsersVisible = atom<boolean>({ key: 'aIsCrossAreaUsersVisible', default: false });
export const aShowAttachmentPanel = atom<boolean>({ key: 'aShowAttachmentPanel', default: false });
export const aNotificationPosition = atom<INotificationPositionOption>({ key: 'aNotificationPosition', default: 'BOTTOM' });
export const aShowVisitorRoomSelectionDialog = atom<boolean>({ key: 'aShowVisitorRoomSelectionDialog', default: false });

export const aRoomSettingsRoomId = atom<string>({ key: 'aRoomSettingsRoomId', default: '' });
export const aShouldUseLocalAvailability = atom<string | undefined>({
    key: 'aShouldUseLocalAvailability',
    default: 'YES',
    effects_UNSTABLE: useLocalOptionsAtomEffects('shouldUseLocalAvailability')
});

export const aStaredAreas = atomFamily<string[], string>({
    key: 'aStaredAreas',
    default: (tid: string) => getOptionArrayLocally('tid:' + tid + ':' + 'staredAreas'),
    effects_UNSTABLE: useLocalOptionsAtomArrayEffects('staredAreas')
});

export const aShowInvitationsManagerModal = atom<boolean>({ key: 'aShowInvitationsManagerPanel', default: false });

export const aShowChangeVisitorEntranceVideoOption = atom<boolean>({ key: 'aShowChangeVisitorEntranceVideoOption', default: false });
