import { OrgBrandingDto } from 'services/client-api-wrapper/identity_provider/responses.dto';
import { IDynamicFormElementProps } from 'components/DynamicForm/DynamicForm';
import { IRoomListsDetails } from 'features/Header/children/CreateInvitationPanel/Data';
import { useOrganizationBranding } from 'features/Header/hooks';
import { formFieldsForWaitingRoom } from 'features/Visitors/UserTab/VisitorsTabController/Data';
import IvicosStrings from 'kits/language/stringKit';
import { useEffect, useState } from 'react';
import { idpService } from 'services/api/identity-provider.service';
import { useTokenSender } from 'services/socket-connection/hooks';
import { useAreaList, useFetchRoomLists, useSelectedVisitor } from 'shared-state/directory/hooks';
import { useCurrentCampusId, useCurrentOrgId } from 'shared-state/location/hook';
import { useVisitorList } from 'shared-state/presence/hooks';

export interface IRoomSelecitonDialogState {
    branding?: OrgBrandingDto;
    eventName?: string;
    visitorName?: string;
    buttonLabel: string;
    formFields: IDynamicFormElementProps[];
    onWaitingRoomFormChange: (key: string, newValue: any) => void;
    letVisitorInside: (visitorId: string, roomId: string, areaId: string) => Promise<false | undefined>;
    changes: any;
    selectedVisitor?: string;
    fetchedRooms: IRoomListsDetails[];
    activeBtn: boolean;
    setActiveBtn: React.Dispatch<React.SetStateAction<boolean>>;
    selectedArea: boolean;
    buttonLabelText: () => string | undefined;
    backToArea: () => void;
}

// Needed to fetch rooms after refreshing
localStorage.setItem('storedAreaId', '');

export const useRoomSelectionDialogState = (): any => {
    const [selectedVisitor] = useSelectedVisitor();
    const [visitors] = useVisitorList();

    const visitor = visitors.find((vis: any) => vis.uid === selectedVisitor);
    const visitorName = visitor?.displayName;

    const [campusId] = useCurrentCampusId();
    const [eventName, setEventName] = useState(IvicosStrings.loadingTitle);
    const [invitationId, setInvitationId] = useState('');
    const [buttonLabel, setButtonLabel] = useState('Let them In');
    const [activeBtn, setActiveBtn] = useState(true);
    const [selectedArea, setSelectedArea] = useState(false);

    const [orgId] = useCurrentOrgId();
    const [branding] = useOrganizationBranding(orgId);

    const sendToken = useTokenSender();

    useEffect(() => {
        console.log('VISITORS', visitors);
    }, [visitors]);

    useEffect(() => {
        if (!visitor || !visitor.invitationId) return;

        const getInvitation = async () => {
            if (visitor.invitationId != undefined) {
                const inv = await idpService.showInvitation(undefined, visitor.invitationId);
                setEventName(inv.event_name);
                setInvitationId(inv.invitationId);
            } else {
                return;
            }
        };
        getInvitation();
    }, [visitor]);

    const currentCampusId = useCurrentCampusId();

    const [changes, setChanges] = useState<any>({});

    const [areaList] = useAreaList();

    const areaNamesList: { key: string; text: string }[] = areaList?.map((area: any) => ({ ['key']: area.id, ['text']: area.name }));

    const [fetchedRooms, setFetchedRooms] = useState<IRoomListsDetails[]>([]);

    const [formFields, setFormFields] = useState<IDynamicFormElementProps[]>(formFieldsForWaitingRoom(changes, areaNamesList, fetchedRooms));

    useEffect(() => {
        if (areaList.length > 0)
            setFormFields(
                formFieldsForWaitingRoom(
                    changes,
                    areaList?.map((area: any) => ({ ['key']: area.id, ['text']: area.name })),
                    fetchedRooms
                )
            );
    }, [areaList]);

    const onChange = (change: { [index: string]: string | boolean }) => {
        setChanges((prevState: any) => {
            const newState = { ...prevState, ...change };
            return newState;
        });
    };

    const onWaitingRoomFormChange = (key: string, newValue: any) => {
        if (key == 'area') {
            const newSelectedArea = newValue.options.find((val: any) => {
                setActiveBtn(true);
                return newValue.selectedKey == val.key;
            });
            if (newSelectedArea.key) {
                setSelectedArea(true);
                localStorage.setItem('storedAreaId', newSelectedArea.key);
            }
        }
        if (key == 'room') {
            setActiveBtn(false);
        }

        onChange({ [key]: newValue });
        setFormFields((prevArray) => {
            return prevArray.map((el: any) => {
                if (el.key != key) return el;
                return { ...el, ...{ value: newValue } };
            });
        });
    };

    const storedAreaId: string | null = localStorage.getItem('storedAreaId');

    useEffect(() => {
        if (currentCampusId[0] && storedAreaId) {
            useFetchRoomLists(currentCampusId[0], storedAreaId, setFetchedRooms);
        }
    }, [storedAreaId]);

    useEffect(() => {
        setFormFields(formFieldsForWaitingRoom(changes, areaNamesList, fetchedRooms));
    }, [fetchedRooms, changes, storedAreaId]);

    const letVisitorInside = async (visitorId: string, roomId: string, areaId: string) => {
        const selectedRoom = fetchedRooms.filter((room) => room.key === roomId)[0];
        if (selectedRoom.video) {
            sessionStorage.setItem(`noVideo-${roomId}`, roomId);
        }

        if (!areaId || !campusId) return;
        setButtonLabel('Loading...');

        const token = await idpService.confirmVisitor(areaId, campusId, roomId, invitationId, visitorId);
        if (!token) {
            setButtonLabel('Error :(');
            return false;
        }

        sendToken(token, visitorId, roomId, areaId, selectedRoom.video);

        setButtonLabel('Success!');
        setActiveBtn(false);
        setSelectedArea(false);

        window.location.href = '/areas/' + areaId + '/rooms/' + roomId;
    };

    const buttonLabelText = () => {
        if (buttonLabel === 'Let them In') {
            return IvicosStrings.letVisitorInActionTitle;
        } else if (buttonLabel === 'Loading...') {
            return IvicosStrings.loadingTitle;
        } else if (buttonLabel === 'Error :(') {
            return IvicosStrings.errorInButton;
        } else if (buttonLabel === 'Success!') {
            return IvicosStrings.successMessage;
        }
    };

    const PreviousAreaId = localStorage.getItem('lastAreaId');
    const backToArea = () => {
        if (PreviousAreaId === undefined || '' || null) {
            window.location.href = '/dashboard/';
        } else {
            window.location.href = '/areas/' + PreviousAreaId;
        }
    };

    return {
        branding,
        eventName,
        visitorName,
        buttonLabel,
        formFields,
        onWaitingRoomFormChange,
        letVisitorInside,
        changes,
        selectedVisitor,
        fetchedRooms,
        activeBtn,
        setActiveBtn,
        selectedArea,
        buttonLabelText,
        backToArea
    };
};
