import { DirectionalHint, IChoiceGroupOption, mergeStyleSets } from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';

interface IChoiceGroupState {
    isCalloutVisible: boolean;
    styles: any;
    toggleIsCalloutVisible: any;
    buttonId: string;
    directionalHint: any;
    onChoiceGroupChange: (ev: React.FormEvent<HTMLInputElement>, option: IChoiceGroupOption) => void;
}

export const useChoiceGroupState: (onChange: any) => IChoiceGroupState = (onChange) => {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');
    const directionalHint = DirectionalHint.rightCenter;
    const styles = mergeStyleSets({
        callout: {
            width: 320,
            padding: '20px 24px'
        }
    });

    const onChoiceGroupChange = (ev: React.FormEvent<HTMLInputElement>, option: IChoiceGroupOption) => {
        console.log('choice: ', option.key);
        onChange(option.key);
    };

    return {
        isCalloutVisible,
        styles,
        toggleIsCalloutVisible,
        buttonId,
        directionalHint,
        onChoiceGroupChange
    };
};
