import { InvitationDto } from 'services/client-api-wrapper/identity_provider/responses.dto';
import { useEffect, useState } from 'react';
import { Params, useNavigate, useParams } from 'react-router';
import { idpService } from 'services/api/identity-provider.service';
import { useCurrentOrgId } from 'shared-state/location/hook';
import jwtDecode from 'jwt-decode';

export interface IVisitorsEntryState {
    orgId?: string;
    linkActive: boolean;
    isLoadingDialogVisible: boolean;
    errorResponse: any;
}

interface IVisitorsEntryRouteParams extends Params {
    formattedOrgName: string;
    customPath: string;
    invitationId: string;
}

export const useVisitorsEntryState: () => IVisitorsEntryState = () => {
    const routeHistory = useNavigate();
    const params = useParams<IVisitorsEntryRouteParams>();
    const customUrl = `${params.formattedOrgName}/${params.customPath}`;
    const invitationId = params.invitationId;
    const [invitation, setInvitation] = useState<InvitationDto | undefined>();
    const [orgId, setOrgId] = useCurrentOrgId();
    const [linkActive, setLinkActive] = useState(true);
    const [errorResponse, setErrorResponse] = useState(undefined);
    const [isLoadingDialogVisible, setIsLoadingDialogVisible] = useState(true);

    const fetchInvitation = async () => {
        return await idpService.showInvitation(customUrl, invitationId);
    };

    const authenticateVisitor = async (id, visitorName = 'Campus Visitor') => {
        try {
            await idpService.authVisitor(visitorName, id);

            const accessToken = localStorage.getItem('ivAccessToken');
            if (!accessToken) throw new Error('No Token');

            const tokenPayload = jwtDecode<any>(accessToken);
            if (accessToken && tokenPayload) {
                const redirectVisitorToLandingPage = customUrl !== 'undefined/undefined' ? customUrl : id;
                routeHistory('/visitorLandingPage/' + redirectVisitorToLandingPage);
            }
        } catch (err) {
            throw new Error('Error Found: ' + err);
        }
    };

    useEffect(() => {
        fetchInvitation()
            .then((res) => {
                setInvitation(res);
                setOrgId(res.orgId);
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    routeHistory('/inserted-invalid-link');
                }
                setErrorResponse(error.response.data.error);
                setOrgId(error?.response?.data?.error?.orgId);
                setLinkActive(false);
            });
    }, [customUrl]);

    useEffect(() => {
        console.log(invitation);
        if (!invitation) return;

        const { invitationId } = invitation;
        linkActive && authenticateVisitor(invitationId);
    }, [invitation]);

    const clean = () => {
        setTimeout(() => {
            setIsLoadingDialogVisible(false);
        }, 1000);
    };

    clean();

    return {
        orgId,
        linkActive,
        isLoadingDialogVisible,
        errorResponse
    };
};
