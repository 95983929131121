/* eslint-disable react/jsx-no-target-blank */
import { getTheme, Stack, Text } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { IvicosColors } from 'kits/colorKit';
import IvicosStrings from 'kits/language/stringKit';
import { BraveLogo, CampusLogo, ChromeLogo, EdgeLogo, SadDoodleGuy } from 'kits/IconKit2';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';

const WarningNotSupportedBrowser: React.FC<any> = (): any => {
    const { smallDevice } = useWindowWidth();
    const browserLanguage = navigator.language.includes('de') ? 'de' : 'en';
    const theme = getTheme();
    const mainFontSize = '17px';
    const altBrowsers = [
        {
            fullName: 'Google Chrome',
            shortName: 'Chrome',
            logoSize: 45,
            intGap: '4px',
            logoComponent: <ChromeLogo />,
            downloadLink: 'https://www.google.com/intl/en/chrome/'
        },
        {
            fullName: 'Microsoft Edge',
            shortName: 'Edge',
            logoSize: 30,
            intGap: '10px',
            logoComponent: <EdgeLogo />,
            downloadLink: 'https://www.microsoft.com/en-us/edge?form=MA13FJ'
        },
        {
            fullName: 'Brave Browser',
            shortName: 'Brave',
            logoSize: 35,
            intGap: '5px',
            logoComponent: <BraveLogo />,
            downloadLink: 'https://brave.com/download/'
        }
    ];
    const footerTextSplitted = IvicosStrings.notSupportedBrowserFooterText.split('/');
    const string2Splitted = IvicosStrings.notSupportedBrowserString2.split('/devices/');
    const string3Splitted = IvicosStrings.notSupportedBrowserString3.split('/browser/');

    return (
        <Stack
            verticalAlign="center"
            horizontalAlign="center"
            style={{ background: theme.palette.neutralLight, height: '100vh' }}
            aria-label="browser-not-supported-warning"
        >
            <Stack
                horizontal
                style={{
                    width: smallDevice ? '100%' : '800px',
                    height: smallDevice ? '100%' : '620px',
                    padding: '40px',
                    columnGap: '25px'
                }}
            >
                <Stack
                    verticalAlign="center"
                    style={{ height: '100%', width: '30%', display: smallDevice ? 'none' : '' }}
                    aria-label="browser-not-supported-warning-doodle"
                >
                    <IC variant="custom" size={250}>
                        <SadDoodleGuy />
                    </IC>
                </Stack>
                <Stack
                    verticalAlign="space-between"
                    style={{
                        width: smallDevice ? '100%' : '70%',
                        height: smallDevice ? '100%' : '540px'
                    }}
                    aria-label="browser-not-supported-warning-logo-text"
                >
                    <Stack style={{ gap: '20px' }}>
                        <IC variant="custom" size={smallDevice ? 270 : 400} style={{ height: '100px', alignItems: 'start' }}>
                            <CampusLogo />
                        </IC>
                        <Text style={{ fontSize: mainFontSize }}>{IvicosStrings.notSupportedBrowserString1}</Text>
                    </Stack>
                    <Text style={{ fontSize: mainFontSize }}>
                        {string2Splitted[0]}
                        <b>{browserLanguage === 'de' ? 'Browser' : 'browsers'}</b>
                        {string2Splitted[1]}
                        <b>{browserLanguage === 'de' ? 'Betriebssysteme' : 'operating systems'}</b>
                        {string2Splitted[2]}
                    </Text>
                    <Text style={{ fontSize: mainFontSize }}>
                        {string3Splitted[0]}
                        <b>{browserLanguage === 'de' ? 'Desktop-' : 'desktop'}</b>
                        {string3Splitted[1]}
                        <b>{browserLanguage === 'de' ? 'iOS-' : 'iOS'}</b>
                        {string3Splitted[2]}
                        <b>Safari</b>
                        {string3Splitted[3]}
                        <b>Firefox</b>
                        {string3Splitted[4]}
                    </Text>
                    <Text style={{ fontSize: mainFontSize }}>{IvicosStrings.notSupportedBrowserString4}</Text>
                    <Stack horizontal horizontalAlign="space-between">
                        {altBrowsers.map((browser) => {
                            return (
                                // eslint-disable-next-line react/jsx-key
                                <Stack
                                    horizontal
                                    style={{ height: '60px', alignItems: 'center', gap: browser.intGap, cursor: 'pointer' }}
                                    onClick={() => window.open(browser.downloadLink)}
                                >
                                    <IC variant="custom" size={browser.logoSize}>
                                        {browser.logoComponent}
                                    </IC>
                                    <Stack>
                                        <Text style={{ fontSize: '14px', fontWeight: 640 }}>{smallDevice ? browser.shortName : browser.fullName}</Text>
                                        <Text style={{ fontSize: '11px' }}>{IvicosStrings.downloadHere}</Text>
                                    </Stack>
                                </Stack>
                            );
                        })}
                    </Stack>
                    <Text style={{ fontSize: '11px' }}>
                        {footerTextSplitted[0]}
                        <a href="https://ivicos.eu" target="_blank" style={{ color: IvicosColors.Mittelblau }}>
                            {footerTextSplitted[1]}
                        </a>
                        {footerTextSplitted[2]}
                        <a href="mailto:support@ivicos.eu?subject=support" style={{ color: IvicosColors.Mittelblau }}>
                            {footerTextSplitted[3]}
                        </a>
                    </Text>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default WarningNotSupportedBrowser;
