/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack } from '@fluentui/react';
import { useOrganizationBranding } from 'features/Header/hooks';
import OutsideWrapper from 'features/Visitors/Entrance/OutsideWrapper/OutsideWrapper';
import { campusDTOtoResource } from 'kits/apiKit3/legacy';
import React from 'react';
import { Navigate } from 'react-router';
import SocketProvider from 'services/socket-connection/context/SocketProvider';
import SocketVisitorEntrance from 'services/socket-connection/socket-visitor-entrance';
import { IWaitingAreaState } from './WaitingArea.state';
import ReactPlayer from 'react-player';
import WaitingAreaSideCard from './WaitingAreaSideCard';
import OutsideCallController from 'features/Visitors/Entrance/OutsideCallController/OutsideCallController';

const WaitingAreaView: React.FC<IWaitingAreaState> = (props) => {
    const {
        headerActions,
        videntityClaims,
        campus,
        customUrl,
        invitationId,
        isTimerActive,
        setIsTimerActive,
        hourSeconds,
        timerProps,
        notifyHost,
        visitorName,
        eventName,
        customizedVideo,
        shouldNotifyHosts,
        hideWhenHostJoin,
        orgId,
        setIsButtonHovered,
        isButtonHovered,
        tokenPayload,
        welcomeMessage,
        isYoutubeVideoVisible,
        isVisitorWithHost
    } = props;

    const redirectVisitor = invitationId ? invitationId : customUrl;

    if (!localStorage.getItem('ivAccessToken')) return <Navigate to={'/i/' + redirectVisitor} />;
    if (!videntityClaims) return;

    const [branding, refetchBranding] = useOrganizationBranding(orgId);
    const showYoutubeVideo = sessionStorage.getItem('isYoutubeVideoVisible') === 'true';

    return (
        <>
            <OutsideWrapper campus={campus && campusDTOtoResource(campus)} headerActions={headerActions}>
                {(shouldNotifyHosts || isVisitorWithHost) && (
                    <SocketProvider>
                        <SocketVisitorEntrance tokenPayload={tokenPayload} eventName={eventName} isVisitorWithHost={isVisitorWithHost} />
                    </SocketProvider>
                )}
                {isYoutubeVideoVisible && showYoutubeVideo ? (
                    <Stack verticalAlign="center" style={{}}>
                        <ReactPlayer url={customizedVideo} controls={true} width={customizedVideo ? '53vw' : 0} height={570} />
                    </Stack>
                ) : (
                    <Stack verticalAlign="center">
                        <OutsideCallController displayName={videntityClaims.displayName} />
                    </Stack>
                )}

                {/* Side card */}
                <Stack verticalAlign="center" style={{ marginTop: 16, width: 340, marginRight: 16, marginLeft: 30 }}>
                    <WaitingAreaSideCard
                        eventName={eventName}
                        branding={branding}
                        visitorName={visitorName}
                        timerProps={timerProps}
                        hourSeconds={hourSeconds}
                        isTimerActive={isTimerActive}
                        setIsTimerActive={setIsTimerActive}
                        welcomeMessage={welcomeMessage}
                        notifyHost={notifyHost}
                        hideWhenHostJoin={hideWhenHostJoin}
                        isButtonHovered={isButtonHovered}
                        setIsButtonHovered={setIsButtonHovered}
                    />
                </Stack>
            </OutsideWrapper>
        </>
    );
};

export default WaitingAreaView;
