/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import type * as Dto from './responses.dto';
// import { getAuthHeader } from '../services/auth_header';
import { AbstractService } from '../shared/abstract-service';

export class ThirdPartyAuthService extends AbstractService {
    // JITSI
    public async getJitsiKey(campusId?: string, areaId?: string, roomId?: string, envFlavor?: string, personalRoomSessionToken?: string) {
        const response = await axios.post(
            `${this.baseUrl}/jitsi/key`,
            {
                campusId: campusId,
                areaId: areaId,
                roomId: roomId,
                envFlavor: envFlavor,
                personalRoomSessionToken: personalRoomSessionToken
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.JitsiKeysDto;
    }

    // PUSHER

    public async getPusherAuth(body: { campusId: string; socketId: string; channelType: string; areaId?: string; userId?: string }) {
        const { campusId, areaId, userId, socketId, channelType } = body;
        const response = await axios.post(
            `${this.baseUrl}/pusher/auth`,
            {
                campusId,
                areaId,
                userId,
                socketId,
                channelType
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.PusherAuthDto;
    }
}
