import { InvitationDto } from '../../services/client-api-wrapper/identity_provider/responses.dto';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { Params, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { aLandingPageClickedGoIn } from 'routes/WaitingArea/atom';
import { idpService } from 'services/api/identity-provider.service';
import { resourceService } from 'services/api/resource.service';
import { useIdentityClaims, useLocalProfile } from 'shared-state/identity/hooks';
import { IIdentityClaims } from 'shared-state/identity/types';
import { useCurrentAreaId, useCurrentCampusId, useCurrentRoomId, useIsVisitorAtLandingPage } from 'shared-state/location/hook';
export interface ILandingPageRouteParams extends Params {
    formattedOrgName: string;
    customPath: string;
    invitationId: string;
}

export interface ILandingPageState {
    videntityClaims?: IIdentityClaims;
    customUrl: string;
    invitationId?: string;
    eventName: string;
    orgId: string;
    isLoadingDialogVisible: string;
    tokenPayload: any;
    goInClicked: (name?: string) => void;
}

export const useLandingPageState: () => ILandingPageState = () => {
    const params = useParams<ILandingPageRouteParams>();
    const customUrl = `${params.formattedOrgName}/${params.customPath}`;
    const invitationId = params.invitationId;
    const routeHistory = useNavigate();
    const [, setCurrentRoomId] = useCurrentRoomId();
    const [, setCurrentAreaId] = useCurrentAreaId();
    const [, setCurrentCampusId] = useCurrentCampusId();
    const [videntityClaims, setVidentityClaims] = useIdentityClaims();
    const localProfile = useLocalProfile();
    const [orgId, setOrgId] = useState('');
    const [, setVisitorName] = useState('');
    const [eventName, setEventName] = useState('');
    const [tokenPayload, setTokenPayload] = useState<any>({});
    const [isLoadingDialogVisible, setIsLoadingDialogVisible] = useState<string>('true');
    const [selectedInvitation, setSelectedInvitation] = useState<InvitationDto | undefined>();
    const [, setLandingPageClickedGoIn] = useRecoilState(aLandingPageClickedGoIn);
    const [, setIsVisitorAtLandingPage] = useIsVisitorAtLandingPage();

    localStorage.setItem('jitsiSessionId', localProfile?.uid || '');

    const invitation = async () => {
        const res = await idpService.showInvitation(customUrl, invitationId);
        setSelectedInvitation(res);
        setOrgId(res.orgId);
        setEventName(res.event_name);
    };

    useEffect(() => {
        const token = localStorage.getItem('ivAccessToken');
        if (!token) return;
        const payload = jwtDecode<any>(token);
        setTokenPayload(payload);
        setCurrentAreaId(payload.areaId);
        setCurrentCampusId(payload.campusId);
        setVisitorName(payload.name);
        const identity: IIdentityClaims = {
            uid: payload.sub.split(':')[2],
            displayName: payload.name,
            roles: [payload.role],
            type: 'visitor',
            tid: payload.orgId,
            upn: payload.email,
            areas: ['*'],
            idp: 'ivicos'
        };
        setVidentityClaims(identity);
        setCurrentAreaId('waiting-area');
        invitation();
        setIsVisitorAtLandingPage(window.location.pathname.includes('/visitorLandingPage'));
    }, []);

    useEffect(() => {
        videntityClaims && setCurrentRoomId('visitor-' + videntityClaims.uid);
    }, [videntityClaims]);

    const clean = () => {
        setTimeout(() => {
            setIsLoadingDialogVisible('false');
        }, 1000);
    };

    clean();

    sessionStorage.setItem('showLoadingAnimation', isLoadingDialogVisible);

    const getAccessToken = () => {
        const accessToken = localStorage.getItem('ivAccessToken');
        if (!accessToken) {
            throw new Error('No Token');
        }
        return accessToken;
    };

    const authenticateAutoConfirmedVisitor = async (id, visitorName) => {
        try {
            await idpService.autoConfirmVisitor(visitorName, id);

            const accessToken = localStorage.getItem('ivAccessToken');
            if (!accessToken) {
                throw new Error('No Token');
            }

            const { role, campusId, areaId, roomId } = jwtDecode<any>(getAccessToken());

            if (role === 'auto-confirmed-visitor') {
                const destinationRoom = await resourceService.getRoomById(campusId, areaId, roomId);
                if (destinationRoom.isAudioOnly) {
                    sessionStorage.setItem(`noVideo-${destinationRoom.id}`, destinationRoom.id);
                }
            }

            if (areaId && roomId) {
                routeHistory(`/areas/${areaId}/rooms/${roomId}`);
                setIsVisitorAtLandingPage(false);
            }
        } catch (err) {
            console.error('Error Found:', err);
        }
    };

    const authenticateUnConfirmedVisitor = async (id, visitorName) => {
        try {
            await idpService.authVisitor(visitorName, id);
            const tokenPayload = jwtDecode<any>(getAccessToken());

            if (tokenPayload) {
                const redirectVisitorToLandingPage = customUrl !== 'undefined/undefined' ? customUrl : id;
                setLandingPageClickedGoIn(true);
                routeHistory('/w/' + redirectVisitorToLandingPage);
                sessionStorage.setItem('isYoutubeVideoVisible', 'true');
            }
        } catch (err) {
            console.error('Error Found:', err);
        }
    };

    const getRandomVisitorNumber = (min: number, max: number): number => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const goInClicked = (name) => {
        if (!selectedInvitation) return;

        const { default_destination, invitationId } = selectedInvitation;

        let userName = name;
        if (!name) {
            const randomNumber = getRandomVisitorNumber(1, 999);
            userName = `Campus Visitor${randomNumber}`;
        }

        if (default_destination.areaId && default_destination.roomId) {
            authenticateAutoConfirmedVisitor(invitationId, userName);
        } else {
            authenticateUnConfirmedVisitor(invitationId, userName);
        }
    };

    return {
        videntityClaims,
        customUrl,
        invitationId,
        eventName,
        orgId,
        tokenPayload,
        isLoadingDialogVisible,
        goInClicked
    };
};
