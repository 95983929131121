import { DefaultEffects, getTheme, PrimaryButton, Stack, Text } from '@fluentui/react';
import { NeutralColors } from '@uifabric/fluent-theme';
import RoomCallController from 'features/Rooms/RoomView/RoomCallController/RoomCallController';
import { sendEvent } from 'kits/eventKit';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useIsVisitorAtLandingPage } from 'shared-state/location/hook';
const theme = getTheme();

export interface IOutsideCallControllerViewProps {
    displayName: string;
    room: string | undefined;
    jwt: string | undefined;
    jitsiRoom: string | undefined;
    domain: string | undefined;
    numberOfParticipants: number;
    setNumberOfParticipants: React.Dispatch<React.SetStateAction<number>>;
}

const OutsideCallControllerView: React.FC<IOutsideCallControllerViewProps> = (props) => {
    const { displayName, room, jwt, jitsiRoom, domain, numberOfParticipants, setNumberOfParticipants } = props;
    const [isVisitorAtLandingPage] = useIsVisitorAtLandingPage();

    if (numberOfParticipants > 1) {
        sendEvent('hideTimerWhenHostJoin', {});
    }

    return (
        <Stack
            style={{
                width: '55vw',
                position: 'relative',
                height: 800,
                paddingBottom: 15
            }}
            verticalAlign="stretch"
        >
            {jwt && room && jitsiRoom && domain ? (
                <RoomCallController
                    callEventListeners={[
                        {
                            eventName: 'participantJoined',
                            handler: () => {
                                setNumberOfParticipants((n) => n + 1);
                            }
                        },
                        {
                            eventName: 'participantLeft',
                            handler: () => {
                                setNumberOfParticipants((n) => n - 1);
                            }
                        }
                    ]}
                    jwt={jwt}
                    roomName={jitsiRoom}
                    domain={domain}
                    actions={isVisitorAtLandingPage ? [[], ['device-settings']] : [[], ['device-settings', 'toggle-video', 'toggle-audio'], ['toggle-view']]}
                    displayName={displayName || 'Visitor'}
                />
            ) : (
                <Stack
                    grow
                    verticalAlign="stretch"
                    style={{
                        borderRadius: 0,
                        overflow: 'hidden',
                        height: '100%',
                        marginTop: 16,
                        boxShadow: DefaultEffects.elevation8,
                        background: NeutralColors.gray200,
                        position: 'relative',
                        marginLeft: 16,
                        marginRight: 16
                    }}
                >
                    <Stack
                        verticalAlign="center"
                        horizontalAlign="center"
                        style={{ width: '100%', minHeight: '100%', height: '100%', color: theme.palette.neutralLighter }}
                    >
                        <Text variant="xLargePlus">{IvicosStrings.PleaseStandByMessage}</Text>
                        <Text variant="large">{IvicosStrings.announcingArrival}</Text>
                        <Stack horizontal tokens={{ childrenGap: 16 }} verticalAlign="center" style={{ marginTop: 32 }}>
                            <Text>{IvicosStrings.callRetryMessage}</Text>
                            <PrimaryButton onClick={() => window.location.reload()}>{IvicosStrings.retryActionTitle}</PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};

export default OutsideCallControllerView;
