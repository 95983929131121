import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getFlag } from 'routes/Flags/Flags';
import { thirdPartyAuthService } from 'services/api/third-party-auth.service';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { useCurrentAreaId, useCurrentCampusId, useCurrentRoomId } from 'shared-state/location/hook';
import { useLocalJitsiDomain, useLocalJitsiRoom, useLocalJitsiToken } from 'shared-state/tokens/hooks';

export const useJitsiToken = (): [string | undefined, string | undefined, string | undefined, Function] => {
    const routeHistory = useNavigate();
    const currentUser = useLocalProfile();
    const [currentRoomId] = useCurrentRoomId();
    const [currentAreaId] = useCurrentAreaId();
    const [currentCampusId] = useCurrentCampusId();
    const [jwt, setJwt] = useLocalJitsiToken(currentRoomId || 'default');
    const [room, setRoom] = useLocalJitsiRoom(currentRoomId || 'default');
    const [domain, setDomain] = useLocalJitsiDomain(currentRoomId || 'default');

    const jitsiSessionId = localStorage.getItem('jitsiSessionId');

    const clearToken = () => setJwt(undefined);

    const fetchToken = async () => {
        // console.log('[JITSIHOOK] Clearing JWT...');
        clearToken();
        const jitsiEnvFlavor = getFlag('jitsiEnv');
        // console.log('[JITSIHOOK]: env flavor: ', jitsiEnvFlavor, getFlag('jitsiEnv'));
        const personalRoomSessionToken = sessionStorage.getItem('tempRoomSessionToken') || '';

        if (currentUser && currentRoomId && currentRoomId.includes('personal') && !currentRoomId.includes(currentUser.uid)) {
            if (!personalRoomSessionToken) {
                console.error('[JITSIHOOK] Error: Valid personalRoomSessionToken is required.');
                routeHistory('/error/403');
                return;
            }
        }

        try {
            const tokenReq = await thirdPartyAuthService.getJitsiKey(currentCampusId, currentAreaId, currentRoomId, jitsiEnvFlavor, personalRoomSessionToken);
            setDomain(tokenReq.domain);
            setRoom(tokenReq.room);
            setJwt(tokenReq.key);
        } catch (err) {
            console.error('Failed to get Jitsi token:', err);
            localStorage.clear();
            sessionStorage.clear();
            routeHistory('/error/403');
        }
        console.log('[JITSIHOOK] Setting JWT...');
    };

    useEffect(() => {
        if (!currentRoomId || !currentCampusId || !currentAreaId) {
            clearToken();
            return;
        }

        fetchToken();
    }, [currentRoomId, currentCampusId, currentAreaId, jitsiSessionId]);

    return [jwt, room, domain, clearToken];
};
