import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { idpService } from 'services/api/identity-provider.service';
import LoadingDialog from 'components/LoadingDialog/LoadingDialog';
import IvicosStrings from 'kits/language/stringKit';
import { useLanguage } from 'components/context/LanguageContext';

const CreateNewUserAccount: React.FC<{}> = () => {
    const { setSelectedLanguageOption }: any = useLanguage();
    const routeHistory = useNavigate();
    const search = useLocation().search;
    const emailFromParam = new URLSearchParams(search).get('email');
    const urlLanguage = new URLSearchParams(search).get('language');
    const languageFromParam = urlLanguage && ['en', 'de'].includes(urlLanguage) ? urlLanguage : 'en';

    const sendMagicLink = (email: string, language: string) => {
        if (email && language) {
            idpService
                .createNewUserAccount(email, language)
                .then((res) => {
                    console.log('🚀 ~ file: CreateNewUserAccount.tsx:21 ~ .then ~ res:', res);
                    sessionStorage.setItem('InvEmail', email);
                    IvicosStrings.setLanguage(language);
                    localStorage.setItem('chosenLanguage', language || (navigator.language === 'de' ? 'de' : 'en'));
                    localStorage.setItem('UserLanguageChoice', language);
                    setTimeout(() => {
                        routeHistory('/accountCreationSuccessful');
                    }, 2000);
                })
                .catch((err: Error) => {
                    console.log('🚀 ~ file: CreateNewUserAccount.tsx:30 ~ sendMagicLink ~ err:', err);
                    setTimeout(() => {
                        routeHistory('/accountCreationSomethingWentWrong');
                    }, 2000);
                });
        } else {
            setTimeout(() => {
                routeHistory('/accountCreationSomethingWentWrong');
            }, 2000);
        }
    };

    useEffect(() => {
        if (emailFromParam && languageFromParam) {
            setSelectedLanguageOption(languageFromParam);
            sendMagicLink(emailFromParam, languageFromParam);
        }
    }, []);

    return (
        <div>
            <Stack
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    justifyContent: 'center'
                }}
            >
                <Stack
                    style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        height: 425,
                        width: 375,
                        backgroundColor: '#fff',
                        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
                    }}
                >
                    <Stack
                        style={{
                            height: 375,
                            width: 375,
                            marginTop: 30
                        }}
                    >
                        <LoadingDialog
                            loadingStrings={[IvicosStrings.creatingAnAccountLoadingString, IvicosStrings.waitingForResponseLoadingString]}
                            view="small"
                            loop={true}
                            typewriter={true}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </div>
    );
};

export default CreateNewUserAccount;
